// Imports
import { Billboard, Html } from "@react-three/drei";
import { useRef, useState, useEffect } from "react";
import * as THREE from "three";
import { useSnapshot } from "valtio";
import { Copy } from "@icons";
import StoreModel, {
  setMeasurements,
  handleMeasurementCopy
} from "./StoreModel";
import { getMidPoint, calculateLength, getAvarageMidPoint, round } from "./utils";


// RULER TOOL
export const RulerTool = ({ model }) => {
  const { points } = useSnapshot(StoreModel.ruler);
  const { pointerScale } = useSnapshot(StoreModel.pointer);

  const rulerToolRef = useRef();

  // Recalculate measurements
  useEffect(() => {
    setMeasurements();
  }, [points]);

  const rulerPoints = points.map((point, i) => {
    return (
      <RulerPoint key={i} position={point} pointerScale={pointerScale} />
    );
  });

  const rulerLines = points.map((point, i, points) => {
    if (points.length >= 2 && i <= points.length - 2) {
      return (
        <RulerLine key={i} start={point} end={points[i+1]} model={model} pointerScale={pointerScale} />
      );
    } else return false;
  });

  return (
    <mesh ref={rulerToolRef}>
      {rulerPoints}
      {rulerLines}
      <RulerLineTotalText model={model} />
    </mesh>
  );
};

const RulerPoint = ({ position, pointerScale }) => {
  const rulerPointRef = useRef();

  return(
    <mesh ref={rulerPointRef} position={position} >
      <sphereGeometry attach="geometry" args={[pointerScale*0.06, 24, 16]} />
      <meshBasicMaterial attach="material" color={"#00FF7F"}/>
    </mesh>
  );
};

const RulerLine = ({ start, end, model, pointerScale }) => {
  const rulerLineRef = useRef();
  const line = new THREE.LineCurve3(start, end);

  return(
    <group>
      <mesh ref={rulerLineRef} >
        <tubeGeometry args={[line, 1, pointerScale*0.02, 6, false]} />
        <meshBasicMaterial attach="material" color={"#00FF7F"} />
      </mesh>
      <RulerLineText start={start} end={end} model={model} />
    </group>
  );
};

const RulerLineText = ({ start, end, model }) => {
  const rulerLineTextRef = useRef();
  const [hidden, set] = useState();

  let midPoint = getMidPoint(start, end);
  let lineLength = calculateLength(start, end, 2);

  return(
    <Billboard ref={rulerLineTextRef} follow={true} position={midPoint}>
      <Html className="rulerline-text" center occlude={[model]} onOcclude={set} style={{opacity: hidden ? 0.2 : 1}} position={[0, 0, 0.5]}>
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleMeasurementCopy(lineLength);
          }}
        >
          <div className="meta">
            <span className="value">{lineLength} m</span>
          </div>
          <Copy/>
        </button>
      </Html>
    </Billboard>
  );
};

const RulerLineTotalText = ({ model }) => {
  const { points, closed, totalLength } = useSnapshot(StoreModel.ruler);
  const rulerLineTotalTextRef = useRef();
  const [hidden, set] = useState();

  let midPoint = getAvarageMidPoint(points, closed);
  let lineTotalLength = round(totalLength, 2);

  if (points.length >= 3) {
    return(
      <Billboard ref={rulerLineTotalTextRef} follow={true} position={midPoint}>
        <Html className="rulerline-text total" center occlude={[model]} onOcclude={set} style={{opacity: hidden ? 0.4 : 1}} position={[0, 0, 0.5]}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleMeasurementCopy(lineTotalLength);
            }}
          >
            <div className="meta">
              <span className="value">{lineTotalLength} m</span>
            </div>
            <Copy
              color="white"
            />
          </button>
        </Html>
      </Billboard>
    );
  }
};