// Imports
import { useContext, useEffect } from "react";
import { useSnapshot } from "valtio";
import { getPartnerRequests, getRequests, getScopedRequest } from "@apis-teto";
import {
  Button,
  ModalContext,
  Row,
  TableRequests,
  useToastContext,
} from "@components-common";
import { StoreRequestsList, StoreRequestsListProp } from "@store-common";

const DashboardAnalytics = () => {
  const snap = useSnapshot(StoreRequestsListProp);
  const snapList = useSnapshot(StoreRequestsList);
  const { modalOpen, modalType } = useContext(ModalContext);
  const addToast = useToastContext();

  // Get and open modal with oldest request
  const getOldestRequest = () => {
    // Filter function
    const filteredData =
      snapList &&
      snapList.filter((item) => item.status && item.status === "Pending");
    // Sorting table
    filteredData.sort((a, b) => a.tminus.sec - b.tminus.sec);

    // Oldest request
    const oldestRequest = filteredData[0];
    let body = {
      token: localStorage.getItem("userToken"),
      id: oldestRequest.id,
    };
    getScopedRequest(body, addToast);
    modalOpen(true);
    modalType("requests");
  };

  return (
    <div className="dashboard__analytics --col-lg-6 --col-xl-3 --col-xxl-3">
      <div className="dashboard__analytics-top">
        <Row justify={["xsSpaceBetween"]}>
          <div className="dashboard__analytics-top__data">
            <p>{snap.request_list_length}</p>
            <p>Igények</p>
          </div>
          <hr />
          <div className="dashboard__analytics-top__data">
            <p>{snap.request_list_pending}</p>
            <p>Várakozik</p>
          </div>
        </Row>
        <div>
          <Button onClickEvent={getOldestRequest}>Igények kezelése</Button>
        </div>
      </div>
    </div>
  );
};
const DashboardMain = () => {
  const snap = useSnapshot(StoreRequestsList);
  const addToast = useToastContext();

  useEffect(() => {
    let body = {
      token: localStorage.getItem("userToken"),
    };
    getRequests(body, addToast);
    getPartnerRequests(body);
  }, [addToast]);

  // Filter function
  const filteredData =
    snap && snap.filter((item) => item.status && item.status === "Pending");

  return (
    <div className="dashboard__main --col-lg-12 --col-xl-9 --col-xxl-9">
      <section className="table__box dashboard">
        <div className="table__box__header">
          <h4>Várakozó igények</h4>
        </div>
        <div className="table__box__table">
          <TableRequests data={filteredData} expanded={false} />
        </div>
      </section>
    </div>
  );
};
export const Dashboard = () => {
  return (
    <section>
      <Row gap={["sm"]}>
        <DashboardAnalytics />
        <DashboardMain />
      </Row>
    </section>
  );
};
