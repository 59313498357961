import { proxy } from "valtio";

// Default states
export const StoreNewPass = proxy({
  oldPassword: "",
  newPassword: "",
});

// Set user old password
export const SetOldPass = (value) => (StoreNewPass.oldPassword = value);

// Set user new passwod
export const SetNewPass = (value) => (StoreNewPass.newPassword = value);
