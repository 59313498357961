// Imports
import { Row } from "@components-common";
import { ArrowLeft, ArrowRight } from "@icons";

export const Carousel = ({
  prevStatus,
  prevUser,
  nextStatus,
  nextUser,
  modal_type,
  addToast,
  currentPendingIndex,
  sortedData,
}) => {
  return (
    <div className="carousel">
      <Row justify={["xsSpaceBetween"]}>
        <div className={`carousel__icon left ${!prevStatus && "inactive"}`}>
          <button
            disabled={prevStatus ? false : true}
            onClick={() =>
              prevUser(modal_type, addToast, currentPendingIndex, sortedData)
            }
          >
            <ArrowLeft color="white" />
          </button>
        </div>
        <div className={`carousel__icon right  ${!nextStatus && "inactive"}`}>
          <button
            disabled={nextStatus ? false : true}
            onClick={() =>
              nextUser(modal_type, addToast, currentPendingIndex, sortedData)
            }
          >
            <ArrowRight color="white" />
          </button>
        </div>
      </Row>
    </div>
  );
};
