// Imports
import { useSnapshot } from "valtio";
import { Navbar } from "@components-common";
import { StoreScopedRequest } from "@store-teto";

export const Header = () => {
  const { contetExpanded } = useSnapshot(StoreScopedRequest);
  return (
    <header style={{ zIndex: contetExpanded ? 1 : 2 }}>
      <Navbar />
    </header>
  );
};
