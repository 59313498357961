// Imports
import { getScopedDomainReqs, urls } from "@apis-common";
import { getRequests } from "@apis-teto";
import { SetScopedDomain } from "@store-common";
import { CheckError } from "@utils-common";

// Get selected domain
export const getScopedDomain = async (body, message) => {
  await fetch(urls.domainByID + body.id, {
    method: "GET",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      getRequests(body, message);
      SetScopedDomain(actualData);
      getScopedDomainReqs(body, message);
    })
    .catch((error) => {
      console.log(error);
    });
};
