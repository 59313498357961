// Imports
import { Row } from "@components-common";
import { ArrowLeft, ArrowRight } from "@icons";

export const CarouselSmall = ({
  prevStatus,
  prevUser,
  nextStatus,
  nextUser,
}) => {
  return (
    <div className="carousel small">
      <Row justify={["xsSpaceBetween"]}>
        <div className={`carousel__icon left ${!prevStatus && "inactive"}`}>
          <button
            disabled={prevStatus ? false : true}
            onClick={() => prevUser()}
          >
            <ArrowLeft color="white" />
          </button>
        </div>
        <div className={`carousel__icon right ${!nextStatus && "inactive"}`}>
          <button
            disabled={nextStatus ? false : true}
            onClick={() => nextUser()}
          >
            <ArrowRight color="white" />
          </button>
        </div>
      </Row>
    </div>
  );
};
