// Imports
import { urls } from "@apis-common";
import { getPCDFile } from "@apis-teto";
import { SetScopedRequest } from "@store-teto";
import { CheckError } from "@utils-common";

// Get scoped request
export const getScopedRequest = async (body, message, takeScreenshots = false) => {
  await fetch(urls.requestByID + body.id, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      // Set Store
      SetScopedRequest(actualData, takeScreenshots);
      // Try LiDAR PCD file
      if (actualData.model_json !== null) getPCDFile(body, message);
    })
    .catch((error) => {
      console.log(error);
    });
};
