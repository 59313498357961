export const SelectDomains = ({ selected, label, domains, name, onChange }) => {
  return (
    <div className="select">
      <label>
        {label}
        <select name={name} value={selected} onChange={onChange}>
          {domains.map((domain) => (
            <option key={domain.domain_id} value={domain.domain_id}>
              {domain.domain_name}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};
