// Imports
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { getDomains } from "@apis-common";
import {
  Table,
  TableDomains,
  useMultiFilter,
  useToastContext,
} from "@components-common";
import { StoreDomainsList } from "@store-common";

export const DomainsPage = () => {
  const snap = useSnapshot(StoreDomainsList);
  const addToast = useToastContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({});
  const filterableColums = [
    "company_vat",
    "company_name",
    "domain_name",
    "domain_id",
  ];
  const filteredData = useMultiFilter(
    snap,
    searchQuery,
    filters,
    filterableColums
  );

  // Fetch data
  useEffect(() => {
    let body = {
      token: localStorage.getItem("userToken"),
    };
    getDomains(body, addToast);
  }, [addToast]);

  return (
    <Table
      title="Domainek"
      searchButtons={true}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      setFilters={setFilters}
      filters={filters}
    >
      <TableDomains data={filteredData} />
    </Table>
  );
};
