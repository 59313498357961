// Imports
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import { defaults } from "ol/control/defaults";
import Point from "ol/geom/Point";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { fromLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import { Icon, Style } from "ol/style";
import { createContext, useEffect, useRef, useState } from "react";
import { useSnapshot } from "valtio";
import { StoreScopedRequest } from "@store-teto";
import PinSel from "@images-teto/pins/Pin.svg";

// Create MapContext context
const MapContext = createContext();

// Map component
export const OLMap = () => {
  const { request, fullscreen } = useSnapshot(StoreScopedRequest);
  const mapRef = useRef();
  const [map, setMap] = useState(null);

  // MOUNT MAP
  useEffect(() => {
    // Default map config
    const baseViewProjection = "EPSG:3857",
      baseViewCenter = [
        parseFloat(request.coordinates.lng),
        parseFloat(request.coordinates.lat),
      ],
      //baseViewCenter = [parseFloat("19.503304"), parseFloat("47.162494")],
      baseViewWMCenter = fromLonLat(baseViewCenter, baseViewProjection),
      initZoom = 17,
      maxViewExtent = () => {
        const c = baseViewWMCenter;
        const p = 10 * 1000;
        return [c[0] - p, c[1] - p, c[0] + p, c[1] + p];
      };

    // Create request's pin and set on map //
    // Example: https://openlayers.org/en/latest/examples/icon.html
    // Example edited with custom style
    // Create icon
    const iconFeature = new Feature({
      geometry: new Point(baseViewWMCenter),
    });
    // Create icon style
    const iconStyle = new Style({
      image: new Icon({
        src: PinSel,
        anchor: [0.5, 1],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
      }),
    });
    // Add icon style to icon
    iconFeature.setStyle(iconStyle);
    // Create vector source
    const vectorSource = new VectorSource({
      features: [iconFeature],
    });
    // Create vector layer
    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    // Map options
    let options = {
      view: new View({
        center: baseViewWMCenter,
        zoom: initZoom,
        projection: baseViewProjection,
        extent: maxViewExtent(),
      }),
      layers: [
        new TileLayer({
          source: new XYZ({
            url: "http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
          }),
        }),
        new TileLayer({
          source: new XYZ({
            url: "https://tileserver{1-8}.envimap.hu/Full_Merge_230404/{z}/{x}/{y}",
            minZoom: 8,
            maxZoom: 18,
          }),
        }),
        vectorLayer,
      ],
      controls: defaults({
        zoom: false,
        rotate: false,
      }),
    };
    let mapObject = new Map(options);
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);
    return () => mapObject.setTarget(undefined);
  }, [request.coordinates]);

  // Update map size when expanded
  useEffect(() => {
    if (map) {
      map.updateSize();
    }
  }, [map, fullscreen]);

  // Return map context
  return (
    <MapContext.Provider value={{ map: map }}>
      <div ref={mapRef} className={"map"} />
    </MapContext.Provider>
  );
};
