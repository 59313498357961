// Imports
import { useContext, useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { getScopedDomain, getScopedDomainReqs } from "@apis-common";
import {
  Badge,
  ButtonClose,
  Carousel,
  CarouselSmall,
  ModalContext,
  useToastContext,
} from "@components-common";
import {
  StoreDomainsList,
  StoreModal,
  StoreRequestsList,
  StoreScopedDomain,
  StoreScopedUser,
  StoreUsersList,
} from "@store-common";
import { StoreScopedRequestForest } from "@store-forest";
import { StoreScopedRequest, setToolbar } from "@store-teto";
import { nextUser, prevUser, sortAndSetData } from "@utils-common";

export const Modal = ({
  isModalOpen,
  title,
  subTitle,
  children,
  status,
  type,
  pagination = true,
  fluid,
}) => {
  const { modalOpen, modalType } = useContext(ModalContext);
  // Snapshots
  const { request: scopedRequest, fullscreen } =
    useSnapshot(StoreScopedRequest);
  const { request: scopedRequestForest, fullscreen: fullscreenForest } =
    useSnapshot(StoreScopedRequestForest);
  const { user: scopedUser } = useSnapshot(StoreScopedUser);
  const { domain: scopedDomain } = useSnapshot(StoreScopedDomain);
  const requestsList = useSnapshot(StoreRequestsList);
  const usersList = useSnapshot(StoreUsersList);
  const domainsList = useSnapshot(StoreDomainsList);
  const { modal_type } = useSnapshot(StoreModal);

  // Local variables
  const [currentPendingIndex, setCurrentPendingIndex] = useState();
  const [sortedData, setSortedData] = useState([]);
  const [canUsePaginator, setCanUsePaginator] = useState(false);
  const [prevStatus, setPrevStatus] = useState();
  const [nextStatus, setNextStatus] = useState();
  const addToast = useToastContext();

  // Pagination variables end
  const openModalWithContent = () => {
    let body = {
      token: localStorage.getItem("userToken"),
      id: scopedUser.domain_id,
    };
    getScopedDomain(body, addToast);
    getScopedDomainReqs(body, addToast);
    modalType("domain");
  };

  // Close modal and init to default state
  const closeModalInits = (e) => {
    e.preventDefault();
    modalOpen(false);
    // Init toolbar in modal
    setToolbar(0);
  };

  // Init pagination
  useEffect(() => {
    let filteredData;
    const setIndex = (indexOfScopedReq, filteredData) => {
      // Set scoped index
      setCurrentPendingIndex(indexOfScopedReq);

      // Set paginator prev status
      if (indexOfScopedReq === 0) setPrevStatus(false);
      else setPrevStatus(true);

      // Set paginator prev status
      if (indexOfScopedReq === filteredData.length - 1) setNextStatus(false);
      else setNextStatus(true);
    };

    // Requests pagination
    if (
      modal_type === "requests" ||
      modal_type === "requestsForest" ||
      modal_type === "requestsForestSaved"
    ) {
      filteredData = requestsList;

      // Set filter
      const serviceFilter = modal_type === "requests" ? "Solar" : "Forest";

      if (modal_type === "requests" || modal_type === "requestsForest") {
        // Filter function
        filteredData = filteredData.filter(
          (item) =>
            item.status &&
            item.status === "Pending" &&
            item.service === serviceFilter
        );
      }

      if (modal_type === "requestsForestSaved") {
        // Filter function
        filteredData = filteredData.filter(
          (item) =>
            item.status &&
            item.status === "Saved" &&
            item.service === serviceFilter
        );
      }

      // Sorting table
      const sortedData = sortAndSetData(
        filteredData,
        "created_at",
        setSortedData
      );

      // Get index of scoped request when user open it
      const indexOfScopedReq = sortedData.findIndex((request) => {
        if (modal_type === "requests")
          return request.id === scopedRequest.request_id;
        if (
          modal_type === "requestsForest" ||
          modal_type === "requestsForestSaved"
        )
          return request.id === scopedRequestForest.request_id;
      });

      // Set scoped index
      setIndex(indexOfScopedReq, sortedData);
    }
    // Users pagination
    if (modal_type === "users") {
      filteredData = usersList;
      // Filter function
      filteredData = filteredData.filter(
        (user) => user.reg_date && user.reg_date !== ""
      );

      // Sorting table
      const sortedData = sortAndSetData(
        filteredData,
        "reg_date",
        setSortedData
      );

      // Get index of scoped user when user open it
      const indexOfScopedReq = sortedData.findIndex(
        (user) => user.id === scopedUser.id
      );
      // Set scoped index
      setIndex(indexOfScopedReq, sortedData);
    }

    // Users pagination
    if (modal_type === "domain") {
      filteredData = domainsList;
      filteredData = filteredData.filter(
        (domain) => domain.created_at && domain.created_at !== ""
      );
      // Get comparable date (create number from date)
      const converterData = (data) => {
        let comparableData = data.replaceAll(".", "");
        comparableData = Math.abs(parseInt(comparableData));
        return comparableData;
      };

      // Sorting table
      filteredData.sort(
        (a, b) => converterData(a.created_at) - converterData(b.created_at)
      );

      // Add sorted list to reference array
      setSortedData(
        filteredData.sort(
          (a, b) => converterData(a.created_at) - converterData(b.created_at)
        )
      );

      // Get index of scoped domain when user open it
      const indexOfScopedReq = filteredData.findIndex(
        (domain) => domain.domain_id === scopedDomain.id
      );

      // Set scoped index
      setIndex(indexOfScopedReq, filteredData);
    }
  }, [
    scopedRequest.request_id,
    scopedRequestForest.request_id,
    requestsList,
    modal_type,
    domainsList,
    scopedUser.id,
    usersList,
    scopedDomain.id,
  ]);

  // Set paginator available
  useEffect(() => {
    if (modal_type === "requests") {
      if (scopedRequest.status === "Pending") setCanUsePaginator(true);
      if (scopedRequest.status !== "Pending") setCanUsePaginator(false);
    } else {
      setCanUsePaginator(true);
    }
  }, [scopedRequest.status]);

  // Set paginator available
  useEffect(() => {
    if (modal_type === "requestsForest") {
      if (scopedRequestForest.status === "Pending") setCanUsePaginator(true);
      if (scopedRequestForest.status !== "Pending") setCanUsePaginator(false);
    }
    if (modal_type === "requestsForestSaved") {
      if (scopedRequestForest.status === "Saved") setCanUsePaginator(true);
      if (scopedRequestForest.status !== "Saved") setCanUsePaginator(false);
    } else {
      setCanUsePaginator(true);
    }
  }, [scopedRequestForest.status]);

  // Properties for paginator
  const paginatorProps = {
    prevStatus,
    nextStatus,
    prevUser,
    nextUser,
    modal_type,
    addToast,
    currentPendingIndex,
    sortedData,
  };
  return (
    <div
      className="modal__layout"
      style={isModalOpen ? { display: "block" } : { display: "none" }}
    >
      <div
        className={`${
          !fullscreen || !fullscreenForest ? "modal__layout-centered" : ""
        }`}
      >
        <div className={`modal ${fluid ? "modal-fluid" : ""}`}>
          {pagination && canUsePaginator && <Carousel {...paginatorProps} />}
          <div className="modal__header">
            <div>
              {type === "request" ? (
                <>
                  {pagination && canUsePaginator && (
                    <CarouselSmall {...paginatorProps} />
                  )}
                  <h2>{title}</h2>
                  <Badge status={status} type="requests" />
                </>
              ) : (
                <div className="modal__header-flex">
                  {pagination && canUsePaginator && (
                    <CarouselSmall {...paginatorProps} />
                  )}
                  <div>
                    <h2>{title}</h2>
                    <h5 onClick={() => openModalWithContent()}>{subTitle}</h5>
                  </div>
                </div>
              )}
            </div>
            <ButtonClose onClickEvent={(e) => closeModalInits(e)} />
          </div>
          <div className="modal__content">{children}</div>
        </div>
      </div>
    </div>
  );
};
