import { useRef, useState, useEffect } from "react";
import { Bin } from "@icons";

export const InputFileListUpload = ({
  name,
  placeholder,
  accept,
  handleChange,
  handleDelete,
  files,
}) => {
  const inputRef = useRef();
  const [drop, setDrop] = useState(false);
  const acceptString = "." + accept.join(", .");

  useEffect(() => {
    if (!files.filename) {
      inputRef.current.value = "";
    }
  }, [files]);

  return (
    <div className="input__listupload">
      <div
        className={`input__listupload__filebox ${drop ? "drop" : ""}`}
        onDragOver={() => setDrop(true)}
        onDrop={() => setDrop(false)}
        onDragLeave={() => setDrop(false)}
      >
        <input
          ref={inputRef}
          name={name}
          type="file"
          accept={acceptString}
          multiple
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <span>+ {placeholder}</span>
      </div>

      <div className="input__listupload__filelist">
        {files.map((file, i) => {
          return (
            <div
              key={i}
              className="input__listupload__filelist__file"
              title={file.filename}
            >
              <div className="input__listupload__filelist__file-filename">
                <span>{file.filename}</span>
              </div>
              <button
                className="input__listupload__filelist__file-remove"
                name={name}
                id={i}
                onClick={handleDelete}
              >
                <Bin />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
