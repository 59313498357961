// Imports
import { useState } from "react";
import { useSnapshot } from "valtio";
import { uploadFilesInRequest } from "@apis-teto";
import {
  Button,
  ButtonGPSCopy,
  InputFileListUpload,
  InputFileUpload,
  useToastContext,
} from "@components-common";
import { Close } from "@icons";
import { StoreScopedRequest } from "@store-teto";

export const ModalRequestFiles = () => {
  const addToast = useToastContext();
  const { request } = useSnapshot(StoreScopedRequest);

  // File Tab
  const fileTabs = ["Shape", "LiDAR", "Egyéb"];
  const [activeFileTab, setActiveFileTab] = useState(0);

  // Request Files initial state (used for delete also)
  const reqFilesInit = {
    shapefile: {
      sides: {
        filename: "",
        files: { shp: null, dbf: null, shx: null, prj: null },
      },
      edges: {
        filename: "",
        files: { shp: null, dbf: null, shx: null, prj: null },
      },
      inters: {
        filename: "",
        files: { shp: null, dbf: null, shx: null, prj: null },
      },
      solars: {
        filename: "",
        files: { shp: null, dbf: null, shx: null, prj: null },
      },
    },
    lidar: {
      filename: "",
      files: { pcd: null },
    },
    other: [],
  };

  // Request files state
  const [reqFiles, setReqFiles] = useState(reqFilesInit);

  // Set file states from input changes
  const handleChange = (e) => {
    e.preventDefault();

    const { name, files } = e.target;
    let filesArray = [];

    if (!files.length) return;

    Array.from(files).forEach((file) => {
      filesArray.push({
        filename: file.name.split(".")[0],
        format: file.name.split(".")[1],
        file: file,
      });
    });

    // Handle SHAPE files
    if (
      name === "sides" ||
      name === "edges" ||
      name === "inters" ||
      name === "solars"
    ) {
      // Check if selected file names match
      const namesMatch = filesArray.every(
        (f) =>
          f.filename ===
          (reqFiles.shapefile[name].filename
            ? reqFiles.shapefile[name].filename
            : filesArray[0].filename)
      );

      // Set files to state
      if (namesMatch) {
        setReqFiles((prevReqFiles) => {
          return {
            ...prevReqFiles,
            shapefile: {
              ...prevReqFiles.shapefile,
              [name]: {
                filename: filesArray[0].filename,
                files: {
                  shp:
                    filesArray.find((e) => e.format === "shp")?.file ||
                    prevReqFiles.shapefile[name].files.shp,
                  dbf:
                    filesArray.find((e) => e.format === "dbf")?.file ||
                    prevReqFiles.shapefile[name].files.dbf,
                  shx:
                    filesArray.find((e) => e.format === "shx")?.file ||
                    prevReqFiles.shapefile[name].files.shx,
                  prj:
                    filesArray.find((e) => e.format === "prj")?.file ||
                    prevReqFiles.shapefile[name].files.prj,
                },
              },
            },
          };
        });
      } else {
        addToast(["error", "Eltérő fájlnevek kerültek kiválasztásra!"]);
      }
    }
    // Handle LIDAR file
    else if (name === "lidar") {
      if (filesArray[0].format === "pcd") {
        setReqFiles((prevReqFiles) => {
          return {
            ...prevReqFiles,
            lidar: {
              filename: filesArray[0].filename,
              files: {
                pcd:
                  filesArray.find((e) => e.format === "pcd").file ||
                  prevReqFiles[name].files.pcd,
              },
            },
          };
        });
      } else {
        addToast(["error", "Nem PCD formátum!"]);
      }
    }
    // Handle ANY OTHER files
    else if (name === "other") {
      filesArray.forEach((file) => {
        setReqFiles((prevReqFiles) => {
          return {
            ...prevReqFiles,
            other: [
              ...prevReqFiles.other,
              {
                filename: file.filename + "." + file.format,
                file: file.file,
              },
            ],
          };
        });
      });
    }
  };

  // Empty file states from delete buttons
  const handleDelete = (e) => {
    e.preventDefault();

    const { name } = e.target;

    if (
      name === "sides" ||
      name === "edges" ||
      name === "inters" ||
      name === "solars"
    ) {
      setReqFiles((prevReqFiles) => {
        return {
          ...prevReqFiles,
          shapefile: {
            ...prevReqFiles.shapefile,
            [name]: reqFilesInit.shapefile[name],
          },
        };
      });
    } else if (name === "lidar") {
      setReqFiles((prevReqFiles) => {
        return {
          ...prevReqFiles,
          lidar: reqFilesInit.lidar,
        };
      });
    } else if (name === "other") {
      setReqFiles((prevReqFiles) => {
        return {
          ...prevReqFiles,
          other: prevReqFiles.other.filter(
            (file) => file !== prevReqFiles.other[e.target.id]
          ),
        };
      });
    }
  };

  // Empty all files
  const removeAllFiles = () => {
    setReqFiles(reqFilesInit);
  };

  // Handle file uploads on form submit
  const handleUpload = () => {
    const token = localStorage.getItem("userToken");
    const requestID = request.request_id;

    // Create new form
    const formData = new FormData();
    // Add blob to form //

    // Edges
    let edgesPath = reqFiles.shapefile.edges.files;
    formData.append("edge1_dbf", edgesPath.dbf === null ? "" : edgesPath.dbf);
    formData.append("edge1_shp", edgesPath.shp === null ? "" : edgesPath.shp);
    formData.append("edge1_shx", edgesPath.shx === null ? "" : edgesPath.shx);
    formData.append("edge1_prj", edgesPath.prj === null ? "" : edgesPath.prj);

    // Inters
    let intersPath = reqFiles.shapefile.inters.files;
    formData.append("edge2_dbf", intersPath.dbf === null ? "" : intersPath.dbf);
    formData.append("edge2_shp", intersPath.shp === null ? "" : intersPath.shp);
    formData.append("edge2_shx", intersPath.shx === null ? "" : intersPath.shx);
    formData.append("edge2_prj", intersPath.prj === null ? "" : intersPath.prj);

    // Sides
    let sidesPath = reqFiles.shapefile.sides.files;
    formData.append("sides_dbf", sidesPath.dbf === null ? "" : sidesPath.dbf);
    formData.append("sides_shp", sidesPath.shp === null ? "" : sidesPath.shp);
    formData.append("sides_shx", sidesPath.shx === null ? "" : sidesPath.shx);
    formData.append("sides_prj", sidesPath.prj === null ? "" : sidesPath.prj);

    // Solar
    let solarPath = reqFiles.shapefile.solars.files;
    formData.append("solar_dbf", solarPath.dbf === null ? "" : solarPath.dbf);
    formData.append("solar_shp", solarPath.shp === null ? "" : solarPath.shp);
    formData.append("solar_shx", solarPath.shx === null ? "" : solarPath.shx);
    formData.append("solar_prj", solarPath.prj === null ? "" : solarPath.prj);

    // LiDAR
    let lidarPath = reqFiles.lidar.files;
    formData.append("lidar_pcd", lidarPath.pcd === null ? "" : lidarPath.pcd);

    let body = {
      token: token,
      id: requestID,
      formData: formData,
    };

    if (!sidesPath.dbf || !sidesPath.shp || !sidesPath.shx || !sidesPath.prj) {
      addToast(["error", "A síkok shape fájljai nincsenek hozzáadva!"]);
    } else {
      uploadFilesInRequest(body, addToast, removeAllFiles);
    }
  };

  return (
    <>
      <div className="modal__tab__group">
        {fileTabs.map((tab, i) => {
          return (
            <button
              key={i}
              className={`modal__tab__item
                ${activeFileTab === i && "active"}
              `}
              onClick={(e) => {
                e.preventDefault();
                setActiveFileTab(i);
              }}
            >
              {tab}
            </button>
          );
        })}
      </div>

      <div className="modal__scroll">
        {activeFileTab === 0 && (
          <div className="modal__form__fields">
            <InputFileUpload
              label="Síkok*"
              name="sides"
              placeholder="Síkok hozzáadása"
              accept={["dbf", "shp", "shx", "prj"]}
              multiple
              handleChange={handleChange}
              handleDelete={handleDelete}
              files={reqFiles.shapefile.sides}
            />
            <InputFileUpload
              label="Élek"
              name="edges"
              placeholder="Élek hozzáadása"
              accept={["dbf", "shp", "shx", "prj"]}
              multiple
              handleChange={handleChange}
              handleDelete={handleDelete}
              files={reqFiles.shapefile.edges}
            />
            <InputFileUpload
              label="Metszett élek"
              name="inters"
              placeholder="Metszett élek hozzáadása"
              accept={["dbf", "shp", "shx", "prj"]}
              multiple
              handleChange={handleChange}
              handleDelete={handleDelete}
              files={reqFiles.shapefile.inters}
            />
          </div>
        )}
        {activeFileTab === 1 && (
          <div className="modal__form__fields">
            {!request.model_json ? (
              <div className="modal__block notify">
                <Close />
                <h5>LiDAR nem feltölthető!</h5>
                <span>
                  A LiDAR fájlok feltöltése előtt szükséges legalább a
                  &ldquo;Síkok&ldquo; shape fájl feltöltése az itt megjelenő
                  model GPS értékek számításához melyekre szüksége van a PCD
                  fájlok konvertálásakor.
                </span>
              </div>
            ) : (
              <>
                <ButtonGPSCopy gps={request.model_json.gps} />
                <InputFileUpload
                  label="LiDAR"
                  name="lidar"
                  placeholder="Pontfelhő hozzáadása"
                  accept={["pcd"]}
                  handleChange={handleChange}
                  handleDelete={handleDelete}
                  files={reqFiles.lidar}
                />
              </>
            )}
          </div>
        )}
        {activeFileTab === 2 && (
          <div className="modal__form__fields">
            <InputFileListUpload
              name="other"
              placeholder="Egyéb fájlok hozzáadása"
              accept={["dwg", "dxf", "obj", "pdf", "png", "jpg", "jpeg"]}
              handleChange={handleChange}
              handleDelete={handleDelete}
              files={reqFiles.other}
            />
          </div>
        )}
      </div>

      <div className="modal__actions">
        <Button onClickEvent={handleUpload} buttonType="small">
          Fájlok feltöltése
        </Button>
      </div>
    </>
  );
};
