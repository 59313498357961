const { transform } = require("ol/proj");

export const convertToGeoJSON = (input) => {
  const { polygons, status } = input;

  const coordinates = polygons.map((polygon) =>
    polygon.map(([lon, lat]) => {
      const [x, y] = transform([lon, lat], "EPSG:4326", "EPSG:3857");
      return [x, y];
    })
  );

  const geojson = {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: {
        name: "EPSG:3857",
      },
    },
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates,
        },
        properties: {
          status,
        },
      },
    ],
  };

  return geojson;
};
