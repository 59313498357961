// Imports
import { useContext, useEffect, useState } from "react";
import { snapshot, subscribe } from "valtio";
import {
  convertUserRole,
  deleteScopedUser,
  editScopedUser,
  editScopedUserActive,
  editScopedUserDomain,
  getScopedUserPrevReqs,
} from "@apis-common";
import { getScopedRequest } from "@apis-teto";
import {
  Button,
  CardNeedSmall,
  Input,
  ModalContext,
  Select,
  SelectDomains,
  useToastContext,
} from "@components-common";
import { ModalLVStats } from "@components-teto";
import { Modal } from "@modals";
import { StoreDomainsList, StoreScopedUser } from "@store-common";
import { formatAddress } from "@utils-common";

export const ModalUser = ({ isModalOpen }) => {
  const [frameData, setFrameData] = useState({});
  const [formData, setFormData] = useState({});
  const addToast = useToastContext();
  const { user } = snapshot(StoreScopedUser);
  const snapPrevReqs = snapshot(StoreScopedUser.user_last_requests);
  const snapModals = snapshot(StoreDomainsList);
  const { modalOpen, modalType } = useContext(ModalContext);

  // Roles
  const roles = [
    {
      value: "admin",
      label: "Admin",
    },
    {
      value: "user",
      label: "Felhasználó",
    },
  ];

  useEffect(() => {
    // Subscribe to Store
    subscribe(StoreScopedUser, () => {
      const snap = snapshot(StoreScopedUser.user);
      if (snap.id) {
        setFrameData({
          id: snap.id,
          first_name: snap.first_name,
          last_name: snap.last_name,
          domain_name: snap.domain_name,
        });
        setFormData({
          first_name: snap.first_name,
          last_name: snap.last_name,
          email: snap.email,
          phone: snap.phone,
          company: snap.company,
          company_vat: snap.company_vat,
          market_segment: snap.market_segment,
          employee_number: snap.employee_number,
          domain_name: snap.domain_name,
          domain_id: snap.domain_id,
          is_active: snap.is_active,
          is_admin: snap.is_admin,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (user.id) {
      let body = {
        token: localStorage.getItem("userToken"),
        userID: user.id,
        domainID: user.domain_id,
      };
      getScopedUserPrevReqs(body, addToast);
    }
  }, [user.domain_id, user.id, addToast]);

  // Handle input change
  const handleInput = (e) => {
    setFormData((prevFormData) => {
      const { name, value, type, checked } = e.target;
      if (type === "select-one" && name === "domain_id") {
        return {
          ...prevFormData,
          domain_id: value,
        };
      }
      if (type === "select-one" && name === "is_admin") {
        return {
          ...prevFormData,
          is_admin: value === "admin" ? true : false,
        };
      } else {
        return {
          ...prevFormData,
          [name]: type === "checkbox" ? checked : value,
        };
      }
    });
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    // Create request body
    let body = {
      id: frameData.id,
      token: localStorage.getItem("userToken"),
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      phone: formData.phone,
      company: formData.company,
      domain_id: formData.domain_id,
      is_active: formData.is_active,
      updated_at: Date().toLocaleString(),
    };
    let bodyDomain = {
      id: frameData.id,
      new_domain_id: formData.domain_id,
      token: localStorage.getItem("userToken"),
    };
    let bodyRole = {
      value: formData.is_admin ? "admin" : "user",
      id: frameData.id,
      token: localStorage.getItem("userToken"),
    };

    // Call user role endpoint
    convertUserRole(bodyRole, addToast);
    // Call edit user endpoint
    editScopedUser(body, addToast, modalOpen);
    // Call edit user domain endpoint
    editScopedUserDomain(bodyDomain, addToast, modalOpen);
  };

  // Handle active/inactive user form
  const handleUserActive = () => {
    let body = {
      token: localStorage.getItem("userToken"),
      userID: frameData.id,
      isActive: user.is_active,
    };
    editScopedUserActive(body, addToast);
  };

  // Handle delete user function
  const deleteScopedUserPassed = () => {
    let body = {
      id: frameData.id,
      token: localStorage.getItem("userToken"),
    };
    deleteScopedUser(body, addToast, modalOpen);
  };

  // Handle deleting user
  const handleDeleteUser = () => {
    addToast([
      // Style of toast
      "error",
      // Short text of toast
      "Biztos kívánja törölni a felhasználót?",
      // Type of toast
      "action",
      // Long text of toast
      "A törlése viszavonhatatlan művelet, azonban ezen felhasználó igényei továbbra is elérhetőek maradnak a domainen belül.",
      // Action of toast
      deleteScopedUserPassed,
      // Action button of toast
      "Törlés megerősítése",
    ]);
  };

  // Open modal with clicked request
  const openModalClickedRequest = (request_id) => {
    let body = {
      token: localStorage.getItem("userToken"),
      id: request_id,
    };
    getScopedRequest(body, addToast);
    modalType("requests");
  };

  // Utility for value
  const utilityCheckValue = (value) => (value === undefined ? "" : value);

  return (
    <Modal
      isModalOpen={isModalOpen}
      type="user"
      title={user.last_name + " " + user.first_name}
      subTitle={user.domain_name}
      fluid
    >
      <main>
        <form className="modal__form" onSubmit={(e) => handleSubmit(e)}>
          <div className="modal__form__fields">
            <div className="modal__form__fields-group wrap">
              <h4>Profil adatok</h4>
              <Input
                value={utilityCheckValue(formData.last_name)}
                onChange={handleInput}
                label="Vezetéknév"
                type="text"
                name="last_name"
              />
              <Input
                value={utilityCheckValue(formData.first_name)}
                onChange={handleInput}
                label="Keresztnév"
                type="text"
                name="first_name"
              />
              <Input
                value={utilityCheckValue(formData.email)}
                onChange={handleInput}
                label="Email"
                type="email"
                name="email"
              />
              <Input
                value={utilityCheckValue(formData.phone)}
                onChange={handleInput}
                label="Telefonszám"
                type="tel"
                name="phone"
              />
            </div>
            {formData.company && (
              <div className="modal__form__fields-group wrap">
                <h4>Regisztrációs adatok</h4>
                <Input
                  value={utilityCheckValue(formData.company)}
                  onChange={handleInput}
                  label="Cég"
                  type="text"
                  name="company"
                  disabled
                />
                <Input
                  value={utilityCheckValue(formData.company_vat)}
                  onChange={handleInput}
                  label="Adószám"
                  type="text"
                  name="company_vat"
                  disabled
                />
                <Input
                  value={utilityCheckValue(formData.market_segment)}
                  onChange={handleInput}
                  label="Piaci szegmens"
                  type="text"
                  name="market_segment"
                  disabled
                />
                <Input
                  value={utilityCheckValue(formData.employee_number)}
                  onChange={handleInput}
                  label="Alkalmazottak száma"
                  type="text"
                  name="employee_number"
                  disabled
                />
              </div>
            )}
            <div className="modal__form__fields-group wrap">
              <h4>Domain</h4>
              <SelectDomains
                type="domain"
                label="Domain"
                domains={snapModals}
                onChange={handleInput}
                name="domain_id"
                selected={formData.domain_id}
                value={utilityCheckValue(formData.domain_id)}
              />
              <Select
                label="Felhasználói szint"
                options={roles}
                onChange={handleInput}
                name="is_admin"
                selected={formData.is_admin ? "admin" : "user"}
                value={formData.is_admin ? "admin" : "user"}
              />
            </div>
          </div>
          <div className="modal__actions">
            <Button asLink={true} onClickEvent={() => handleDeleteUser()}>
              Felhasználó törlése
            </Button>
            <div className="modal__actions-buttons">
              <Button
                buttonType={user.is_active ? "danger" : ""}
                onClickEvent={() => handleUserActive()}
              >
                {user.is_active ? "Inaktíválás" : "Aktíválás"}
              </Button>
              <Button actionType="submit">Mentés</Button>
            </div>
          </div>
        </form>
      </main>
      <hr />
      <aside>
        <ModalLVStats
          requests={snapPrevReqs.length}
          date={user.reg_date}
          type="user"
        />
        <div className="modal__block">
          <h4>Legutóbbi igények</h4>
          <div className="modal__list-requests">
            {snapPrevReqs.map((element, index) => {
              return (
                <CardNeedSmall
                  key={index}
                  id={element.request_id}
                  label={formatAddress(element.resolved_addr)}
                  onClick={() => openModalClickedRequest(element.request_id)}
                  status={element.status}
                />
              );
            })}
          </div>
        </div>
      </aside>
    </Modal>
  );
};
