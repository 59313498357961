//Imports
import { urls } from "@apis-common";
import { CheckError } from "@utils-common";

// Get admin information
export const editScopedUser = async (body, message, modalOpen) => {
  await fetch(urls.userByID + body.id, {
    method: "PATCH",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name: body.first_name,
      last_name: body.last_name,
      email: body.email,
      phone: body.phone,
      company: body.company,
    }),
  })
    .then((response) => CheckError(response, message))
    .then(() => {
      // Send success message to user
      message(["success", "Változtatások mentése sikeres."]);
      // Close modal
      modalOpen(false);
    })
    .catch((error) => {
      console.log(error);
    });
};
