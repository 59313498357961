// Imports
import { Tooltip } from "components/service-common/tooltip/Tooltip";
import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getScopedDomain } from "@apis-common";
import {
  Badge,
  BadgeActive,
  ModalContext,
  TableLoader,
  TableNoResults,
  columWidth,
  paginationComponentOptions,
  useToastContext,
} from "@components-common";
import { IconsService } from "@icons";

export const TableDomains = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const { modalOpen, modalType } = useContext(ModalContext);
  const addToast = useToastContext();

  const getSuperDomainName = (domainList, domain_id) => {
    const superDomainName = domainList.filter(
      (domain) => domain.domain_id === domain_id
    );
    if (superDomainName.length === 0) return;
    return superDomainName[0].domain_name;
  };

  console.log(data);

  // Colums
  const colums = [
    {
      selector: (row) => <BadgeActive active={row.is_active} />,
      width: columWidth.small,
    },
    {
      name: "Domain",
      selector: (row) => (
        <div
          style={{ display: "flex", alignItems: "center", overflow: "inherit" }}
          onClick={() => openModalWithContent("domain", row.domain_id)}
        >
          <span style={{ marginRight: "1rem", padding: ".5rem 0" }}>
            {row.domain_name}
          </span>
          {row.parent_id && (
            <Tooltip title={getSuperDomainName(data, row.parent_id)} position="right">
              <Badge status={"SuperDomain"} type="requests" />
            </Tooltip>
          )}
        </div>
      ),
      sortable: true,
      style: {
        fontWeight: 500,
      }
    },
    {
      name: "Felhasználók",
      selector: (row) => (row.users ? row.users + " db" : "-"),
      sortable: true,
      width: columWidth.normal,
    },
    {
      name: "Csomag",
      selector: (row) => (row.on_trial ? "Demo" : "Korlátlan"),
      sortable: true,
      width: columWidth.medium,
    },
    {
      name: "Szolgáltatás",
      selector: (row) => (
        <div style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
          {row.available_services?.map((service, i) => (
            <IconsService key={i} type={service} />
          ))}
        </div>
      ),
      sortable: true,
      width: columWidth.medium,
    },
    {
      name: "Cég",
      selector: (row) => row.company_name,
      sortable: true,
      width: columWidth.large,
    },
    {
      name: "Adószám",
      selector: (row) => row.company_vat,
      width: columWidth.normal,
    },
    {
      name: "Létrehozva",
      selector: (row) => row.created_at,
      sortable: true,
      width: columWidth.dateDate,
    },
  ];

  // Conditional styleing
  const conditionalRowStyles = [
    {
      when: (row) => !row.is_active,
      style: {
        opacity: 0.75,
      },
    },
  ];

  // Loading state handling
  useEffect(() => {
    if (data.length > 1) {
      setLoading(false);
    }
  }, [data]);

  // Modal open function
  const openModalWithContent = (type, id) => {
    let body = {
      token: localStorage.getItem("userToken"),
      id: id,
    };
    getScopedDomain(body, addToast);
    modalOpen(true);
    modalType(type);
  };

  return (
    <DataTable
      data={data}
      columns={colums}
      conditionalRowStyles={conditionalRowStyles}
      onRowClicked={(row) => {
        openModalWithContent("domain", row.domain_id);
      }}
      progressPending={loading}
      progressComponent={<TableLoader />}
      noDataComponent={
        <TableNoResults text="Nem található a keresésnek megfelelő domain!" />
      }
      pagination
      paginationPerPage={50}
      paginationComponentOptions={paginationComponentOptions}
      responsive
    />
  );
};
