// Imports
import { urls } from "@apis-common";
import { SetDomainsList } from "@store-common";
import { CheckError } from "@utils-common";

// Get domains list
export const getDomains = async (body, message) => {
  await fetch(urls.domains, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => SetDomainsList(actualData))
    .catch((error) => console.log(error));
};
