import { proxy } from "valtio";
import { formatDate, getBuildingFeature, getTMinus } from "@utils-common";

const requestInit = {
  request_id: null,
  status: "",
  status_initial: "",
  service: "",
  order_details: {
    building_features: "",
    building_type: "",
    selected_layers: [],
    price: 0,
  },
  times: {
    created_at: "",
    updated_at: "",
    tminus: "",
  },
  coordinates: {
    lat: 0,
    lng: 0,
  },
  resolved_addr: "",
  domain: {
    domain_id: "",
    domain_name: "",
  },
  user: {
    id: null,
    email: null,
    last_name: null,
    first_name: null,
  },
  api: {
    id: null,
    name: null,
    meta: null,
  },
  comment: "",
  model_json: null,
  pcd_file: null,
  takeScreenshots: false,
  screenshotStage: 0,
};

// STORE
export const StoreScopedRequest = proxy({
  request: requestInit,
  toolbar: 0,
  fullscreen: false,
});

// Toolbar switch
export const setToolbar = (value) => {
  StoreScopedRequest.toolbar = value;
};

// Set scoped request
export const SetScopedRequest = (request, takeScreenshots) => {
  StoreScopedRequest.request = {
    request_id: request.request_id,
    status: request.status,
    service: request.service,
    order_details: {
      building_features: getBuildingFeature(request.building_features),
      building_type: request.building_type,
    },
    times: {
      created_at: formatDate(request.created_at),
      updated_at: formatDate(request.updated_at),
      tminus:
        request.status === "Pending" ? getTMinus(request.created_at) : null,
    },
    coordinates: {
      lat: request.lat,
      lng: request.lon,
    },
    resolved_addr: request.resolved_addr,
    domain: {
      domain_id: request.domain_id,
      domain_name: request.domain.domain_name,
    },
    user: {
      id: request.user?.id || null,
      email: request.user?.email || null,
      last_name: request.user?.last_name || null,
      first_name: request.user?.first_name || null,
    },
    api: {
      id: request.partner?.id || null,
      name: request.partner?.name || null,
      meta: request.partner_meta || null
    },
    comment: request.comment,
    model_json: request.model_json,
    pcd_file: null,
    takeScreenshots: takeScreenshots,
    screenshotStage: 0,
  };
};

// Set scoped request PCD file
export const SetScopedRequestPCD = (blob) => {
  StoreScopedRequest.request.pcd_file = blob;
};

// Clear scoped request
export const ClearScopedRequest = () => {
  StoreScopedRequest.request = requestInit;
};

// Set scoped request status
export const setScopedRequestStatus = (status) => {
  StoreScopedRequest.request.status = status
    ? "Available"
    : StoreScopedRequest.request.status_initial;
};

// Set screenshot taking
export const setTakeScreenshots = (value) => {
  StoreScopedRequest.request.takeScreenshots = value;
};

// Set the stage of the screenshoting process
export const setScreenshotStage = (value) => {
  StoreScopedRequest.request.screenshotStage = value;
};

// Set expanded
export const setFullscreen = () => {
  StoreScopedRequest.fullscreen = !StoreScopedRequest.fullscreen;
};
