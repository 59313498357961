// Imports
import { useContext, useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { getAdmin, newPass } from "@apis-common";
import {
  Button,
  Input,
  ModalContext,
  useToastContext,
} from "@components-common";
import { ViewHide, ViewShow } from "@icons";
import { Modal } from "@modals";
import {
  SetAdminName,
  SetNewPass,
  SetOldPass,
  SnapAdminData,
  StoreNewPass,
} from "@store-common";

export const ModalProfile = ({ isModalOpen }) => {
  const { oldPassword, newPassword } = useSnapshot(StoreNewPass);
  const { adminName } = useSnapshot(SnapAdminData);
  const { modalOpen } = useContext(ModalContext);
  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showNewtPass, setShowNewtPass] = useState(false);
  const addToast = useToastContext();

  const formHandler = (e) => {
    e.preventDefault();
    // Create request body
    let body = {
      old_password: oldPassword,
      new_password: newPassword,
      token: localStorage.getItem("userToken"),
    };
    // Call new password generate endpoint
    newPass(body, addToast, SetNewPass, SetOldPass, modalOpen);
  };

  useEffect(() => {
    // Create request body
    let body = {
      token: localStorage.getItem("userToken"),
    };
    // Call get admin data endpoint
    getAdmin(body, addToast);
  }, [addToast]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      title={"Fiók"}
      hasFooter={true}
      pagination={false}
    >
      <form onSubmit={formHandler} className="modal__form-fixed">
        <h4>Személyes adatok</h4>
        <div className="modal__content-input">
          <Input
            value={adminName}
            label="Név"
            type="text"
            name="username"
            onChange={(e) => SetAdminName(e.target.value)}
          />
        </div>
        <hr />
        <h4>Jelszó módosítása</h4>
        <div className="modal__content-input">
          <label>
            Aktuális jelszó
            <div className="modal__content-input-container">
              <input
                value={oldPassword}
                onChange={(e) => SetOldPass(e.target.value)}
                id="currentPass"
                type={showCurrentPass ? "text" : "password"}
                name="currentPass"
                autoComplete="on"
              />
              <span
                onMouseDown={() => setShowCurrentPass(true)}
                onMouseUp={() => setShowCurrentPass(false)}
              >
                <ViewHide className={showCurrentPass ? "d-none" : ""} />
                <ViewShow className={showCurrentPass ? "" : "d-none"} />
              </span>
            </div>
          </label>
        </div>
        <div className="modal__content-input">
          <label>
            Új jelszó
            <div className="modal__content-input-container">
              <input
                value={newPassword}
                onChange={(e) => SetNewPass(e.target.value)}
                id="newPass"
                type={showNewtPass ? "text" : "password"}
                name="newPass"
                autoComplete="on"
              />
              <span
                onMouseDown={() => setShowNewtPass(true)}
                onMouseUp={() => setShowNewtPass(false)}
              >
                <ViewHide className={showNewtPass ? "d-none" : ""} />
                <ViewShow className={showNewtPass ? "" : "d-none"} />
              </span>
            </div>
          </label>
        </div>
        <div className="modal__form-fixed-actions">
          <Button buttonType="neutral">Mégse</Button>
          <Button actionType="submit">Mentés</Button>
        </div>
      </form>
    </Modal>
  );
};
