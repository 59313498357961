// Imports
import Map from "ol/Map";
import View from "ol/View";
import { defaults } from "ol/control/defaults";
import GeoJSON from "ol/format/GeoJSON";
import { Vector as VectorLayer } from "ol/layer.js";
import { fromLonLat } from "ol/proj";
import { Vector as VectorSource } from "ol/source";
import { createContext, useEffect, useRef, useState } from "react";
import { useSnapshot } from "valtio";
import { Button } from "@components-common";
import { Download } from "@icons";
import { StoreScopedRequestForest } from "@store-forest";
import { downloadGeoJSON } from "@utils-common";
import { calculateInitZoom, convertToGeoJSON } from "@utils-forest";
import { backgroundLayerForest, satalaiteLayerForest } from "./LayersForest";
import { prevStyleFunction } from "./Styles";
import { getCenterOfExtent } from "./Utils";

// Create MapContext context
const MapContext = createContext();

// Map component
export const OLMap = () => {
  const { request, fullscreen } = useSnapshot(StoreScopedRequestForest);
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [geoJSONObjectToDownload, setGeoJSONObjectToDownload] = useState({});

  
  // MOUNT MAP
  useEffect(() => {
    if (request.request_data.polygons.length !== 0) {
      // Create layer for polygon
      const polygonData = request.request_data;
      const geojsonObject = convertToGeoJSON(polygonData);

      // Pass geoJSON to download button
      setGeoJSONObjectToDownload(geojsonObject);

      const previousSource = new VectorSource({
        features: new GeoJSON().readFeatures(geojsonObject),
      });
      const previousLayer = new VectorLayer({
        title: "previous-layer",
        source: previousSource,
        style: (feature) => prevStyleFunction(feature),
      });

      // Get request polygon's extent
      const requestExtent = previousLayer
        .getSource()
        .getFeatures()[0]
        .getGeometry()
        .getExtent();

      const requestExtentCenter = getCenterOfExtent(requestExtent);

      // Default map config
      const baseViewProjection = "EPSG:3857",
        baseViewCenter = [
          parseFloat(requestExtentCenter[0]),
          parseFloat(requestExtentCenter[1]),
        ],
        baseViewWMCenter = fromLonLat(baseViewCenter, baseViewProjection),
        maxViewExtent = () => {
          const paddingPercentage = 1;
          const dx = (requestExtent[2] - requestExtent[0]) * paddingPercentage;
          const dy = (requestExtent[3] - requestExtent[1]) * paddingPercentage;
          return [
            requestExtent[0] - dx,
            requestExtent[1] - dy,
            requestExtent[2] + dx,
            requestExtent[3] + dy,
          ];
        },
        initZoom = calculateInitZoom(requestExtent, maxViewExtent());

      // Map options
      let options = {
        view: new View({
          center: baseViewWMCenter,
          zoom: initZoom,
          projection: baseViewProjection,
          extent: maxViewExtent(),
        }),
        layers: [backgroundLayerForest, satalaiteLayerForest, previousLayer],
        controls: defaults({
          zoom: false,
          rotate: false,
        }),
      };
      let mapObject = new Map(options);
      mapObject.setTarget(mapRef.current);
      setMap(mapObject);
      return () => mapObject.setTarget(undefined);
    }
  }, [request.coordinates]);

  // Update map size when expanded
  useEffect(() => {
    if (map) {
      map.updateSize();
    }
  }, [map, fullscreen]);

  // Return map context
  return (
    <MapContext.Provider value={{ map: map }}>
      <div ref={mapRef} className={"map"}>
        <div
          style={{
            position: "absolute",
            left: "1rem",
            bottom: "1rem",
            zIndex: "1",
          }}
        >
          <Button
            buttonType="white"
            onClickEvent={() =>
              downloadGeoJSON(
                geoJSONObjectToDownload,
                `ERD-${request.request_id.toString()}`
              )
            }
          >
            <Download />
          </Button>
        </div>
      </div>
    </MapContext.Provider>
  );
};
