// Set default icon color
//
// To add a new color, create a new case with the value and
// name of the color.

export const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="24"
      fill="none"
      viewBox="0 0 104 24"
    >
      <path
        fill="#00FF7F"
        d="M67.391 20.808L67.8652 19.8451C67.8422 19.9631 67.8299 20.0824 67.8299 20.2032C67.8299 20.4087 67.8649 20.6103 67.9309 20.8082H67.391V20.808ZM68.3727 18.8154L75.3793 4.59455C75.607 4.13239 75.9435 3.75747 76.3566 3.49075C76.8119 3.1969 77.3451 3.03838 77.9194 3.0381V3.03223C78.4892 3.03223 79.0199 3.19075 79.4761 3.4846V3.49047C79.8929 3.75943 80.2312 4.13239 80.4578 4.58896L80.4602 4.58812L87.5384 18.9219C86.7458 17.85 84.9856 16.9595 82.6919 16.435L77.9191 6.76972L73.1595 16.4299C70.9309 16.9363 69.203 17.7876 68.3727 18.8159V18.8154ZM87.9368 19.7285L88.47 20.808H87.8981C87.9641 20.6103 87.9991 20.4084 87.9991 20.2029C87.9991 20.0425 87.9776 19.8842 87.9371 19.7285H87.9368Z"
      />
      <path
        fill="#0A0A29"
        d="M48.5425 20.7622L48.598 5.37633C48.5999 4.83198 48.7997 4.32872 49.1441 3.92836C49.4913 3.5252 49.9791 3.2266 50.5543 3.09463L50.554 3.09324C51.1311 2.96211 51.7147 3.01523 52.2264 3.22101C52.7403 3.4279 53.1777 3.78633 53.4595 4.26386L57.6208 11.3119L61.8036 4.24177C62.0869 3.76284 62.5243 3.40497 63.0376 3.19892C63.5518 2.99287 64.1359 2.9403 64.7109 3.07227C65.2861 3.20451 65.7739 3.50423 66.1202 3.90907H66.1266C66.4723 4.31335 66.6712 4.81716 66.6712 5.35843H66.6647L66.6611 20.8424H63.3682L63.3713 7.92642L59.8979 13.7974C59.671 14.1813 59.3441 14.4894 58.9481 14.7047C58.5537 14.9191 58.1009 15.0343 57.6199 15.0343V15.0402C57.145 15.0402 56.6922 14.9222 56.2919 14.7044C55.8953 14.4883 55.5684 14.1799 55.3425 13.7971L51.882 7.93593L51.8356 20.7624L48.5425 20.7622Z"
      />
      <path
        fill="#0A0A29"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.0337 20.7622L89.0628 5.59776C89.0634 5.24436 89.1337 4.91473 89.2725 4.61138C89.414 4.30272 89.6209 4.02425 89.8926 3.7785C90.1621 3.5347 90.4684 3.34878 90.8089 3.22185C91.149 3.09491 91.5112 3.03201 91.8925 3.03341L96.555 3.05354V3.04766H96.5597C98.6136 3.04878 100.473 3.80786 101.819 5.03384C103.167 6.26093 104 7.95634 104 9.82844C104 11.6891 103.172 13.378 101.832 14.6063C100.495 15.8306 98.6452 16.5924 96.5962 16.6036H92.3342L92.3262 20.7624L89.0337 20.7622ZM92.3551 6.03419L92.3404 13.6042H96.5836C97.7197 13.5981 98.7486 13.1728 99.4946 12.489C100.237 11.8093 100.695 10.8691 100.695 9.82816C100.695 8.78698 100.231 7.8445 99.4823 7.16232C98.7336 6.48013 97.6985 6.05824 96.5554 6.05824V5.61621L96.5535 6.05236L92.3551 6.03419Z"
      />
      <path
        fill="#0A0A29"
        d="M68.5102 18.5363C68.9335 18.3028 69.3961 18.0881 69.8934 17.8935C71.9916 17.0732 74.7902 16.5722 77.8246 16.5722C80.6948 16.5722 83.3713 17.024 85.439 17.7767H85.4454C86.1813 18.0445 86.848 18.3557 87.4315 18.7052L88.4697 20.8074H84.8512L84.8429 20.7903V20.8113C84.647 20.7266 84.4386 20.6441 84.2185 20.5638L84.2194 20.5619C82.5357 19.9502 80.2917 19.5828 77.8243 19.5828C75.2295 19.5828 72.8901 19.9901 71.1842 20.6569C71.0578 20.7064 70.9359 20.7565 70.8183 20.8074H67.3907L68.5102 18.5363Z"
      />
      <path
        fill="#0A0A29"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.42756 9.32425C9.91514 9.32425 10.9914 9.63068 11.656 10.2432C12.3206 10.8558 12.653 11.7172 12.653 12.8269C12.653 13.8793 12.3285 14.69 11.6796 15.2593C11.0307 15.8285 9.93877 16.1134 8.40361 16.1134H2.44502V16.9351C2.44502 17.3964 2.59144 17.7747 2.88429 18.0702C3.17714 18.3658 3.67167 18.5134 4.36788 18.5134H12.0357V20.7403H4.36788C3.57651 20.7403 2.90793 20.6466 2.36183 20.4593C1.81573 20.272 1.36479 20.0089 1.0087 19.67C0.652618 19.3312 0.395378 18.935 0.237288 18.4809C0.0788913 18.0269 0 17.5259 0 16.9782V13.0863C0 12.5529 0.0791983 12.0558 0.237288 11.5945C0.395378 11.1332 0.652618 10.7334 1.0087 10.3945C1.36479 10.0556 1.81573 9.79284 2.36183 9.60524C2.90793 9.41791 3.57651 9.32425 4.36788 9.32425H8.42756ZM2.44532 14.3188H8.33271C9.01326 14.3188 9.49183 14.1963 9.76902 13.9511C10.0459 13.7062 10.1843 13.3746 10.1843 12.9566C10.1843 12.5241 10.0419 12.1783 9.75705 11.9188C9.47218 11.6593 9.02124 11.5296 8.40392 11.5296H4.36819C3.67198 11.5296 3.17714 11.6809 2.8846 11.9837C2.59175 12.2865 2.44532 12.6611 2.44532 13.1079V14.3188Z"
      />
      <path
        fill="#0A0A29"
        d="M21.1281 9.32425C23.2647 9.32425 24.8514 9.7604 25.8877 10.6324C26.9244 11.5045 27.4425 12.7332 27.4425 14.3188V20.7403H24.9023V14.3188C24.9023 13.4107 24.6055 12.7187 24.0121 12.2431C23.4188 11.7675 22.4573 11.5296 21.1278 11.5296H17.0209C16.8152 11.5296 16.7124 11.6378 16.7124 11.8539V20.7403H14.1961V11.0971C14.1961 10.4197 14.3306 9.95472 14.5998 9.70253C14.8687 9.45034 15.32 9.32425 15.9529 9.32425H21.1281Z"
      />
      <path
        fill="#0A0A29"
        d="M30.2673 9.32425L34.4454 18.4918C34.5403 18.7225 34.7463 18.838 35.0627 18.838C35.3952 18.838 35.6168 18.7225 35.7273 18.4918L40.0716 9.32425H42.7778L37.9351 19.5081C37.6659 20.0558 37.2981 20.4414 36.8312 20.6648C36.3643 20.8882 35.7749 21 35.0627 21C34.3349 21 33.7373 20.8848 33.2703 20.6542C32.8034 20.4235 32.4514 20.0416 32.2141 19.5081L27.6086 9.32425H30.2673Z"
      />
      <path
        fill="#0A0A29"
        d="M46.0059 9.32425V20.7403H43.4897V9.32425H46.0059Z"
      />
      <path
        fill="#0A0A29"
        d="M46.3005 6.06606C46.3005 6.84197 45.6098 7.47097 44.7578 7.47097C43.9058 7.47097 43.2151 6.84197 43.2151 6.06606C43.2151 5.29014 43.9058 4.66114 44.7578 4.66114C45.6098 4.66114 46.3005 5.29014 46.3005 6.06606Z"
      />
    </svg>
  );
};

// Set default icon color
//
// To add a new color, create a new case with the value and
// name of the color.

let fillColor;
export const ColorSwitch = (propColor) => {
  switch (propColor) {
    case "green":
      fillColor = "#00FF7F";
      break;
    case "white":
      fillColor = "#FFFFFF";
      break;
    case "red":
      fillColor = "#FF0055";
      break;
    default:
      fillColor = "#000000";
      break;
  }
  return fillColor;
};

// Icons
//
// To add an icon, create an arrow function with the name of the icon,
// give it the input value "props", call the ColorSwitch function at
// the beginning with the value "props.color" and return the svg code.

export const ArrowDropDown = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M6.66666 10.6665L12 15.9998L17.3333 10.6665H6.66666Z"/>
      </g>
    </svg>
  );
};

export const ArrowForward = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M12 4L10.5867 5.41333L16.1733 11H4V13H16.1733L10.5867 18.5867L12 20L20 12L12 4Z"/>
      </g>
    </svg>
  );
};

export const ArrowRight = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/>
      </g>
    </svg>
  );
};

export const ArrowLeft = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"
      ></path>
    </svg>
  );
};

export const Bin = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M15 3v2h6v2H3V5h6V3h6zM11 12H9v6h2v-6zM13 12h2v6h-2v-6z"/>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 22c-1.103 0-2-.897-2-2V8h14v12c0 1.103-.897 2-2 2H7zm10-12H7v10h10.001L17 10z"/>
      </g>
    </svg>
  );
};

export const Copy = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
      </g>
    </svg>
  );
};

export const Expand = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M13 6h3.586L6 16.586V13H4v7h7v-2H7.414L18 7.414V11h2V4h-7v2z"/>
      </g>
    </svg>
  );
};

export const ExpandLess = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M18.6 4L20 5.4 15.4 10H19v2h-7V5h2v3.6L18.6 4zM12 12H5v2h3.6L4 18.6 5.4 20l4.6-4.6V19h2v-7z"/>
      </g>
    </svg>
  );
};

export const Model = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 18V6L12 2 2 6v12l10 4 10-4zM17.961 6.538L12 4.154 6.039 6.538 12 8.923l5.961-2.385zM20 7.877l-7 2.8v8.769l7-2.8v-8.77zm-9 2.8l-7-2.8v8.769l7 2.8v-8.77z"
        />
      </g>
    </svg>
  );
};

export const Pin = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M12 2c-4.411 0-8 3.589-8 8s4.644 9.44 8 12.318c3.354-2.876 8-7.907 8-12.318s-3.589-8-8-8zm0 17.654C9.87 17.657 6 13.338 6 10c0-3.309 2.691-6 6-6s6 2.691 6 6c0 3.338-3.87 7.657-6 9.654z"/>
        <path d="M14 10a2 2 0 11-4 0 2 2 0 014 0z"/>
      </g>
    </svg>
  );
};

export const Plus = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M21 11.001H13V3.00098H11V11.001H3V13.001H11V21.001H13V13.001H21V11.001Z"/>
      </g>
    </svg>
  );
};

export const Rectangle = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="9"
      fill="none"
      viewBox="0 0 13 9"
    >
      <g fill={fillColor}>
        <path d="M6.25.5l6 8h-12l6-8z"/>
      </g>
    </svg>
  );
};

export const RectangleDown = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="9"
      fill="none"
      viewBox="0 0 13 9"
    >
      <g fill={fillColor}>
        <path d="M6.25 8.5l-6-8h12l-6 8z"/>
      </g>
    </svg>
  );
};

export const Search = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fillColor}>
        <path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"/>
      </g>
    </svg>
  );
};

export const User = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g fill={fillColor}>
        <path d="M16.002 8C16.002 10.205 14.208 12 12.002 12C9.79701 12 8.00201 10.205 8.00201 8C8.00201 5.795 9.79601 4 12.002 4C14.208 4 16.002 5.795 16.002 8Z"/>
        <path d="M4.00201 19C4.00201 15.467 7.29201 13 12.002 13C16.713 13 20.002 15.467 20.002 19V20H4.00201V19Z"/>
      </g>
    </svg>
  );
};

export const UserFilled = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect width="32" height="32" fill="#0A0A29" rx="16"></rect>
      <path
        fill="#fff"
        d="M20.002 12c0 2.205-1.794 4-4 4-2.205 0-4-1.795-4-4s1.794-4 4-4 4 1.795 4 4zM8.002 23c0-3.533 3.29-6 8-6 4.711 0 8 2.467 8 6v1h-16v-1z"
      ></path>
    </svg>
  );
};

export const Camera = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 12.999C7 10.242 9.243 7.99902 12 7.99902C14.757 7.99902 17 10.241 17 12.999C17 15.757 14.757 17.999 12 17.999C9.243 17.999 7 15.757 7 12.999ZM9 12.999C9 14.653 10.346 15.999 12 15.999C13.654 15.999 15 14.653 15 12.999C15 11.345 13.654 9.99902 12 9.99902C10.346 9.99902 9 11.345 9 12.999Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.535 5.99902H20C21.103 5.99902 22 6.89602 22 7.99902V18.999C22 20.102 21.103 20.999 20 20.999H4C2.897 20.999 2 20.102 2 18.999V7.99902C2 6.89602 2.897 5.99902 4 5.99902H7.465L8.871 3.89002C9.243 3.33202 9.865 2.99902 10.535 2.99902H13.465C14.135 2.99902 14.757 3.33202 15.129 3.89002L16.535 5.99902ZM4 7.99902V18.999H20.001L20 7.99902H15.465L13.465 4.99902H10.535L8.535 7.99902H4Z"
        />
      </g>
    </svg>
  );
};

export const Service = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path
          d="M5 5H10V7H5V5Z"
        />
        <path
          d="M15 7C15.5523 7 16 6.55228 16 6C16 5.44772 15.5523 5 15 5C14.4477 5 14 5.44772 14 6C14 6.55228 14.4477 7 15 7Z"
        />
        <path
          d="M19 6C19 6.55228 18.5523 7 18 7C17.4477 7 17 6.55228 17 6C17 5.44772 17.4477 5 18 5C18.5523 5 19 5.44772 19 6Z"
        />
        <path
          d="M10 11H5V13H10V11Z"
        />
        <path
          d="M15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13Z"
        />
        <path
          d="M19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12Z"
        />
        <path
          d="M5 17H10V19H5V17Z"
        />
        <path
          d="M15 19C15.5523 19 16 18.5523 16 18C16 17.4477 15.5523 17 15 17C14.4477 17 14 17.4477 14 18C14 18.5523 14.4477 19 15 19Z"
        />
        <path
          d="M19 18C19 18.5523 18.5523 19 18 19C17.4477 19 17 18.5523 17 18C17 17.4477 17.4477 17 18 17C18.5523 17 19 17.4477 19 18Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2H20C21.104 2 22 2.896 22 4V20C22 21.103 21.104 22 20 22H4C2.896 22 2 21.103 2 20V4C2 2.896 2.896 2 4 2ZM20 4H4V8H19.999L20 4ZM19.999 10H4V14H19.998L19.999 10ZM4 16V20H19.997L19.998 16H4Z"
        />
      </g>
    </svg>
  );
};

// ----------------- Not Used ------------- //

export const ArrowBackward = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
      ></path>
    </svg>
  );
};

export const ArrowDown = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const Calendar = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 5H19V2H17V7H16V5H9V2H7V7H6V5H4C2.897 5 2 5.898 2 7V20C2 21.103 2.897 22 4 22H20C21.103 22 22 21.103 22 20V7C22 5.898 21.103 5 20 5ZM19.997 20H4V9H20L19.997 20Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const Clock = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21C7.038 21 3 16.963 3 12C3 7.037 7.038 3 12 3C16.962 3 21 7.037 21 12C21 16.963 16.962 21 12 21ZM12 5C8.14 5 5 8.14 5 12C5 15.86 8.14 19 12 19C15.86 19 19 15.86 19 12C19 8.14 15.86 5 12 5Z"
        fill={fillColor}
      />
      <path
        d="M14.293 15.707L11.293 12.707C11.105 12.519 11 12.265 11 12V7H13V11.586L15.707 14.293L14.293 15.707Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const Done = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.00002 16.2001L4.80002 12.0001L3.40002 13.4001L9.00002 19.0001L21 7.0001L19.6 5.6001L9.00002 16.2001Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const Download = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M16.293 9.294l1.414 1.414L12 16.415l-5.707-5.707 1.414-1.414L11 12.587V2.001h2v10.586l3.293-3.293zM18 20.001v-2h2v2c0 1.102-.896 2-2 2H6c-1.104 0-2-.898-2-2v-2h2v2h12z"
      ></path>
    </svg>
  );
};

export const FileDefault = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M6 2h9.414L20 6.586V20c0 1.103-.897 2-2 2H6c-1.103 0-2-.897-2-2V4c0-1.102.897-2 2-2zm0 18h12.001L18 8h-4V4H6v16z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const Hamburger = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect x="1" y="5" width="22" height="2" fill={fillColor} />
      <rect x="1" y="11" width="22" height="2" fill={fillColor} />
      <rect x="1" y="17" width="22" height="2" fill={fillColor} />
    </svg>
  );
};

export const Help = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M12.092 6.241A3.84 3.84 0 008.16 9.982a.096.096 0 00.096.098h1.73c.05 0 .093-.04.095-.09A1.92 1.92 0 1112 12h-.864a.096.096 0 00-.096.096v1.728c0 .053.043.096.096.096H12a3.84 3.84 0 003.84-3.933 3.854 3.854 0 00-3.747-3.746zM12 16.8a.96.96 0 100-1.92.96.96 0 000 1.92z"
      ></path>
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M12 2.4A9.6 9.6 0 002.4 12a9.6 9.6 0 009.6 9.6 9.6 9.6 0 009.6-9.6A9.6 9.6 0 0012 2.4zm0 17.28a7.68 7.68 0 110-15.36 7.68 7.68 0 010 15.36z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const Information = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"
      ></path>
    </svg>
  );
};

export const Mail = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const Marker = (props) => {
  ColorSwitch(props.color);

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect width="20" height="20" rx="10" fill="#00FF7F" />
      <path
        d="M13.06 7.72656L10 10.7799L6.94 7.72656L6 8.66656L10 12.6666L14 8.66656L13.06 7.72656Z"
        fill="#0A0A29"
      />
    </svg>
  );
};

export const Payment = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM20 8H4V6H20V8Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const Phone = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.62 10.79C8.06 13.62 10.38 15.93 13.21 17.38L15.41 15.18C15.68 14.91 16.08 14.82 16.43 14.94C17.55 15.31 18.76 15.51 20 15.51C20.55 15.51 21 15.96 21 16.51V20C21 20.55 20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4 3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const Receipt = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 8H7V10H12V8Z" fill={fillColor} />
      <path d="M15 8H17V10H15V8Z" fill={fillColor} />
      <path d="M11 11H7V13H11V11Z" fill={fillColor} />
      <path d="M15 11H17V13H15V11Z" fill={fillColor} />
      <path d="M13 14H7V16H13V14Z" fill={fillColor} />
      <path d="M15 14H17V16H15V14Z" fill={fillColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.526 2.15C19.231 1.967 18.863 1.951 18.552 2.106L15.072 3.846L12.554 2.168C12.218 1.944 11.781 1.944 11.445 2.168L8.929 3.846L5.448 2.106C5.138 1.951 4.77 1.967 4.475 2.15C4.18 2.332 4 2.653 4 3V21C4 21.347 4.18 21.668 4.475 21.851C4.635 21.951 4.817 22 5 22C5.153 22 5.307 21.965 5.447 21.895L8.928 20.155L11.445 21.832C11.781 22.057 12.218 22.057 12.554 21.832L15.071 20.155L18.552 21.895C18.863 22.05 19.231 22.034 19.526 21.851C19.821 21.668 20 21.347 20 21V3C20 2.653 19.82 2.332 19.526 2.15ZM18 19.382L15.447 18.106C15.125 17.945 14.742 17.969 14.445 18.168L12 19.798L9.555 18.168C9.388 18.057 9.194 18 9 18C8.848 18 8.694 18.035 8.553 18.106L6 19.382V4.619L8.553 5.894C8.873 6.054 9.256 6.031 9.555 5.831L12 4.202L14.445 5.831C14.743 6.031 15.127 6.054 15.447 5.894L18 4.619V19.382Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const SadPin = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fillColor}
        d="M9.24926 14.3465C10.1274 14.1205 11.0489 14 12 14C12.9511 14 13.8726 14.1205 14.7507 14.3465C15.2856 14.4842 15.8308 14.1622 15.9684 13.6273C16.1061 13.0925 15.7841 12.5473 15.2493 12.4096C14.2097 12.142 13.1207 12 12 12C10.8793 12 9.79034 12.142 8.75074 12.4096C8.21589 12.5473 7.8939 13.0925 8.03156 13.6273C8.16922 14.1622 8.7144 14.4842 9.24926 14.3465Z"
      />
      <path
        fill={fillColor}
        d="M11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z"
      />
      <path
        fill={fillColor}
        d="M15 11C16.1046 11 17 10.1046 17 9C17 7.89543 16.1046 7 15 7C13.8954 7 13 7.89543 13 9C13 10.1046 13.8954 11 15 11Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 10C4 5.589 7.589 2 12 2C16.411 2 20 5.589 20 10C20 14.411 15.354 19.442 12 22.318C8.644 19.44 4 14.411 4 10ZM6 10C6 13.338 9.87 17.657 12 19.654C14.13 17.657 18 13.338 18 10C18 6.691 15.309 4 12 4C8.691 4 6 6.691 6 10Z"
      />
    </svg>
  );
};

export const Substract = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M21 11.001H3V13.001H21V11.001Z" fill={fillColor} />
    </svg>
  );
};

export const ViewHide = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M22.8 3.2l-1.6-1.6L1.6 20.8l1.6 1.6 4.554-4.46c1.228.521 2.587.86 4.046.86 6.222 0 10.633-6.162 10.817-6.424a.996.996 0 000-1.15c-.105-.15-1.577-2.209-3.972-3.956L22.8 3.2zm-5.613 5.498l-1.666 1.633a4.004 4.004 0 01-5.266 5.159l-.923.903c.781.255 1.608.407 2.468.407 4.18 0 7.552-3.572 8.731-4.999-.604-.728-1.781-2.016-3.343-3.103zm-3.4 3.331a2.003 2.003 0 01-1.799 1.762l1.799-1.762z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fillColor}
        d="M11.8 4.8c.89 0 1.742.126 2.55.343l-1.737 1.702A7.43 7.43 0 0011.8 6.8c-4.18 0-7.552 3.573-8.73 5a18.08 18.08 0 002.208 2.23l-1.44 1.411c-1.73-1.49-2.768-2.94-2.855-3.065a.996.996 0 010-1.15C1.167 10.961 5.578 4.8 11.8 4.8z"
      ></path>
    </svg>
  );
};

export const ViewShow = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M11.8 7.8c-2.206 0-4 1.795-4 4 0 2.206 1.794 4 4 4s4-1.794 4-4c0-2.205-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2 0-1.102.897-2 2-2s2 .898 2 2c0 1.103-.897 2-2 2z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M22.617 11.225C22.433 10.962 18.022 4.8 11.8 4.8S1.167 10.962.983 11.225a.996.996 0 000 1.15c.184.263 4.595 6.425 10.817 6.425s10.633-6.162 10.817-6.424a.996.996 0 000-1.151zM11.8 16.8c-4.166 0-7.549-3.575-8.73-5.001 1.178-1.426 4.55-5 8.73-5 4.166 0 7.549 3.577 8.731 5.002C19.352 13.228 15.98 16.8 11.8 16.8z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const Cursor = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      {props.multi ? (
        <>
          <path
            fill={fillColor}
            d="M10.4207 21.9996C9.99753 21.9996 9.61017 21.7438 9.44702 21.348L2.07876 3.4537C1.91771 3.06213 2.00824 2.60951 2.30823 2.30846C2.60823 2.00742 3.06085 1.91794 3.45347 2.07899L21.3478 9.44725C21.7678 9.62093 22.0299 10.0441 21.9973 10.4978C21.9636 10.9514 21.6436 11.3314 21.2015 11.443L13.3932 13.3945L11.4417 21.2028C11.3312 21.6428 10.9501 21.9638 10.4965 21.9964C10.4723 21.9986 10.4449 21.9996 10.4207 21.9996V21.9996ZM4.98712 4.9884L10.1765 17.5881L11.5049 12.2725C11.5996 11.8956 11.8943 11.5998 12.2722 11.5062L17.5889 10.1767L4.98712 4.9884V4.9884Z"
          />
          <path
            fill={fillColor}
            d="M17 15C17 14.4477 17.4477 14 18 14C18.5523 14 19 14.4477 19 15V17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H19V21C19 21.5523 18.5523 22 18 22C17.4477 22 17 21.5523 17 21V19H15C14.4477 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17H17V15Z"
          />
        </>
      ) : (
        <path
          fill={fillColor}
          d="M10.4207 21.9996C9.99753 21.9996 9.61017 21.7438 9.44702 21.348L2.07876 3.4537C1.91771 3.06213 2.00824 2.60951 2.30823 2.30846C2.60823 2.00742 3.06085 1.91794 3.45347 2.07899L21.3478 9.44725C21.7678 9.62093 22.0299 10.0441 21.9973 10.4978C21.9636 10.9514 21.6436 11.3314 21.2015 11.443L13.3932 13.3945L11.4417 21.2028C11.3312 21.6428 10.9501 21.9638 10.4965 21.9964C10.4723 21.9986 10.4449 21.9996 10.4207 21.9996V21.9996ZM4.98712 4.9884L10.1765 17.5881L11.5049 12.2725C11.5996 11.8956 11.8943 11.5998 12.2722 11.5062L17.5889 10.1767L4.98712 4.9884V4.9884Z"
        />
      )}
    </svg>
  );
};

export const Ruler = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M16.3828 0L0 16.3828L7.61709 23.9999L23.9999 7.61709L16.3828 0ZM16.3828 2.75061L21.2493 7.61709L7.61709 21.2493L2.75062 16.3828L3.869 15.2644L5.95463 17.3501L7.34506 15.9899L5.2292 13.874L6.3778 12.7556L7.22415 13.602L8.58434 12.2115L7.738 11.3954L8.88661 10.2468L10.9722 12.3325L12.3324 10.9723L10.2468 8.88662L11.3954 7.73801L12.2115 8.58436L13.602 7.22416L12.7556 6.37782L13.874 5.22921L15.9899 7.34507L17.35 5.95465L15.2644 3.86901L16.3828 2.75061Z"
      />
    </svg>
  );
};

export const Close = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M20.749 4.707l-1.415-1.414-7.292 7.293-7.293-7.293-1.415 1.414L10.627 12l-7.293 7.293 1.415 1.414 7.293-7.293 7.292 7.293 1.415-1.414L13.456 12l7.293-7.293z"
      ></path>
    </svg>
  );
};

export const LidarRes = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        d="M12 7.00098C13.1046 7.00098 14 6.10555 14 5.00098C14 3.89641 13.1046 3.00098 12 3.00098C10.8954 3.00098 10 3.89641 10 5.00098C10 6.10555 10.8954 7.00098 12 7.00098Z"
      />
      <path
        fill={fillColor}
        d="M18 10.5C19.1046 10.5 20 9.60457 20 8.5C20 7.39543 19.1046 6.5 18 6.5C16.8954 6.5 16 7.39543 16 8.5C16 9.60457 16.8954 10.5 18 10.5Z"
      />
      <path
        fill={fillColor}
        d="M8 8.5C8 9.60457 7.10457 10.5 6 10.5C4.89543 10.5 4 9.60457 4 8.5C4 7.39543 4.89543 6.5 6 6.5C7.10457 6.5 8 7.39543 8 8.5Z"
      />
      <path
        fill={fillColor}
        d="M12 14.001C13.1046 14.001 14 13.1055 14 12.001C14 10.8964 13.1046 10.001 12 10.001C10.8954 10.001 10 10.8964 10 12.001C10 13.1055 10.8954 14.001 12 14.001Z"
      />
      <path
        fill={fillColor}
        d="M20 15.5C20 16.6046 19.1046 17.5 18 17.5C16.8954 17.5 16 16.6046 16 15.5C16 14.3954 16.8954 13.5 18 13.5C19.1046 13.5 20 14.3954 20 15.5Z"
      />
      <path
        fill={fillColor}
        d="M6 17.5C7.10457 17.5 8 16.6046 8 15.5C8 14.3954 7.10457 13.5 6 13.5C4.89543 13.5 4 14.3954 4 15.5C4 16.6046 4.89543 17.5 6 17.5Z"
      />
      <path
        fill={fillColor}
        d="M14 19.001C14 20.1055 13.1046 21.001 12 21.001C10.8954 21.001 10 20.1055 10 19.001C10 17.8964 10.8954 17.001 12 17.001C13.1046 17.001 14 17.8964 14 19.001Z"
      />
    </svg>
  );
};

export const Features = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      {props.type === "side" && (
        <path
          fill={fillColor}
          d="M4.41421 11L12 3.41421L19.5858 11H19H18V12V21H6V12V11H5H4.41421Z"
        />
      )}
      {props.type === "edge" && (
        <path
          fill={fillColor}
          d="M12 2L22 12H19V21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21V12H2L12 2ZM6.82843 10H17.1716L12 4.82843L6.82843 10ZM7 12V20H17V12H7Z"
        />
      )}
      {props.type === "solar" && (
        <>
          <path fill={fillColor} d="M13 7H11V8H10V10H11V11H13V10H14V8H13V7Z" />
          <path
            fill={fillColor}
            d="M11 13H13V14H14V16H13V17H11V16H10V14H11V13Z"
          />
          <path
            fill={fillColor}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 2C5.44772 2 5 2.44772 5 3V21C5 21.5523 5.44772 22 6 22H18C18.5523 22 19 21.5523 19 21V3C19 2.44772 18.5523 2 18 2H6ZM7 4V8H8V10H7V14H8V16H7V20H11V19H13V20H17V16H16V14H17V10H16V8H17V4H13V5H11V4H7Z"
          />
        </>
      )}
    </svg>
  );
};
