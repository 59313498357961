// Imports
import { useContext, useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { Button, ModalContext, SearchBar, Select } from "@components-common";
import { StoreRequestsList } from "@store-common";
import { compareValues } from "@utils-common";

// React Data Table Defaults
export const columWidth = {
  small: "4rem",
  medium: "8rem",
  normal: "10rem",
  large: "12rem",
  xl: "20rem",
  dateCount: "6rem",
  dateDate: "9rem",
  dateTime: "11rem",
};
export const paginationComponentOptions = {
  rangeSeparatorText: "/",
  selectAllRowsItem: true,
  noRowsPerPage: true,
};

export const HeaderButtons = () => {
  const { modalOpen, modalType } = useContext(ModalContext);

  const openModalWithContent = (type) => {
    modalOpen(true);
    modalType(type);
  };

  return (
    <div onClick={() => openModalWithContent("domainNew")}>
      <Button>Új domain</Button>
    </div>
  );
};

export const Table = ({
  title,
  children,
  searchButtons = false,
  searchQuery,
  setSearchQuery,
  filters,
  setFilters,
  selects,
}) => {
  const requestList = useSnapshot(StoreRequestsList);
  const [options, setOptions] = useState([]);
  const [optionsStatus, setOptionsStatus] = useState([]);

  // Set new filters
  useEffect(() => {
    const availableOptions = [...new Set(requestList.map((req) => req.status))];
    let newOptions = [];
    availableOptions.map((option) => {
      let obj;

      switch (true) {
        case option === "Pending":
          obj = { value: option, label: "Várakozik" };
          break;
        case option === "Available":
          obj = { value: option, label: "Teljesítve" };
          break;
        case option === "Archived":
          obj = { value: option, label: "Archív" };
          break;
        case option === "Re-requested":
          obj = { value: option, label: "Újraigényelt" };
          break;
        default:
          obj = { value: option, label: option };
          break;
      }
      return newOptions.push(obj);
    });
    // Sorting
    newOptions.sort(compareValues("label"));
    // Add all option to the first place
    newOptions.unshift({ value: "", label: "Összes" });
    // Set new value
    setOptions(newOptions);
  }, [requestList]);

  // Set new status filter
  useEffect(() => {
    const availableOptions = [
      ...new Set(requestList.map((req) => req.service)),
    ];
    let newOptions = [];
    availableOptions.map((option) => {
      let obj;
      switch (true) {
        case option === "Solar":
          obj = { value: option, label: "Envimap Tető" };
          break;
        case option === "Forest":
          obj = { value: option, label: "Envimap Erdő" };
          break;
        case option === "GeoStore":
          obj = { value: option, label: "Téradatbank" };
          break;
        default:
          obj = { value: option, label: option };
          break;
      }
      return newOptions.push(obj);
    });
    // Sorting
    newOptions.sort(compareValues("label"));
    // Add all option to the first place
    newOptions.unshift({ value: "", label: "Összes" });
    // Set new value
    setOptionsStatus(newOptions);
  }, [requestList]);

  // Get selected filter
  const handleSearchQueryChange = (e) => setSearchQuery(e.target.value);
  const handleFilterChange = (key, value) =>
    setFilters({ ...filters, [key]: value });

  return (
    <section className="table__box">
      <div className="table__box__header">
        <h2>{title}</h2>
        <div className="table__box__header__actions">
          {selects && (
            <>
              <Select
                name="status-select"
                value={filters.service}
                onChange={(e) => handleFilterChange("service", e.target.value)}
                options={optionsStatus}
              />
              <Select
                name="status-select"
                options={options}
                value={filters.status}
                onChange={(e) => handleFilterChange("status", e.target.value)}
              />
            </>
          )}

          <SearchBar
            placeholder="Keresés..."
            searchQuery={searchQuery}
            handleSearchQueryChange={handleSearchQueryChange}
          />
          {searchButtons && <HeaderButtons />}
        </div>
      </div>
      <div className="table__box__table">{children}</div>
    </section>
  );
};
