export const Badge = ({ status, type }) => {
  let text, color;

  if (type === "requests") {
    switch (true) {
      case status === "Pending":
        text = "Várakozik";
        color = "yellow";
        break;
      case status === "Available":
        text = "Teljesítve";
        color = "green";
        break;
      case status === "Denied":
        text = "Elutasítva";
        color = "red";
        break;
      case status === "API":
        text = "API";
        color = "red";
        break;
        case status === "SuperDomain":
          text = "SD";
          color = "black";
          break;
      case status === "Archived":
        text = "Archív";
        color = "gray";
        break;
        case status === "Saved":
          text = "Mentett";
          color = "gray";
          break;
      case status === "Re-requested":
        text = "Újraigényelt";
        color = "yellow";
        break;
      default:
        break;
    }
  } else if (type === "invoices") {
    switch (true) {
      case status === "next":
        text = "Következő";
        color = "gray";
        break;
      case status === "unpaid":
        text = "Nincs fizetve";
        color = "yellow";
        break;
      case status === "paid":
        text = "Fizetve";
        color = "green";
        break;
      default:
        break;
    }
  }

  if (type === "requests" || type === "invoices") {
    return (
      <>
        <span className={`badge-purchase ${color}`}>{text}</span>
        <span className={`badge__medium-purchase ${color}`}></span>
      </>
    );
  } else {
    return (
      <>
        <span className={`badge-small ${color}`}>{text}</span>
        <span className={`badge__medium-small ${color}`}></span>
      </>
    );
  }
};

