// Imports
import { useSnapshot } from "valtio";
import { Button, CardModalForest } from "@components-common";
import { OLMap } from "@components-forest";
import { Modal } from "@modals";
import { StoreScopedRequestForest } from "@store-forest";

export const ModalRequestForestSaved = ({ isModalOpen }) => {
  const { request } = useSnapshot(StoreScopedRequestForest);

  const deleteSavedReq = () => console.log("Törlés");
  const contactUser = () => console.log("Kapcsolatfelvétel");

  return (
    <Modal
      isModalOpen={isModalOpen}
      type="request"
      title={`ERD-${request.request_id}`}
      status={request.status}
      fluid
    >
      <main>
        <div className="modal__view">
          <div id="ortomap" />
          <OLMap saved />
        </div>
      </main>

      <aside>
        <div className="modal__scroll">
          <div className="modal__form__fields">
            <CardModalForest type="date" data={request.times} />
            <CardModalForest type="location" data={request.coordinates} />
            <CardModalForest
              type="request-type"
              data={request.order_details}
              saved
            />
            <CardModalForest
              type="user"
              data={[request.user, request.domain]}
              saved
            />
          </div>
        </div>
        <hr />
        <div className="modal__actions">
          <Button onClickEvent={deleteSavedReq} buttonType="neutral">
            Törlés
          </Button>
          <Button onClickEvent={contactUser}>Kapcsolatfelvétel</Button>
        </div>
      </aside>
    </Modal>
  );
};
