// Imports
import { useSnapshot } from "valtio";
import { StoreScopedRequest } from "@store-teto";
import ScreenshotOverlay from "./ScreenshotOverlay";
import Toolbar from "./Toolbar";
import Viewbar from "./Viewbar";


// HUD
export const HUD = () => {
  const { takeScreenshots } = useSnapshot(StoreScopedRequest.request);

  return (
    <>
      {takeScreenshots
        ? (
          <ScreenshotOverlay/>
        ) : (
          <>
            <Toolbar/>
            <Viewbar/>
          </>
        )
      }
    </>
  );
};