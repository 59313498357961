// Imports
import { Search } from "@icons";

export const SearchBar = ({
  placeholder,
  searchQuery,
  handleSearchQueryChange,
}) => {
  return (
    <div className="search">
      <div className="searchInput">
        <div className="searchIcon">
          <Search />
        </div>
        <input
          type="text"
          placeholder={placeholder}
          aria-label={placeholder}
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
      </div>
    </div>
  );
};
