// Imports
import { urls } from "@apis-common";
import { SetScopedDomainReqs } from "@store-common";
import { CheckError } from "@utils-common";

// Get selected domain
export const getScopedDomainReqs = async (body, message) => {
  await fetch(urls.requestByDomain + body.id, {
    method: "GET",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      SetScopedDomainReqs(actualData);
    })
    .catch((error) => {
      console.log(error);
    });
};
