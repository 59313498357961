// Imports
import { useSnapshot } from "valtio";
import { Row } from "@components-common";
import { Expand, ExpandLess, Model, Pin } from "@icons";
import { StoreScopedRequest, setFullscreen } from "@store-teto";

export const Viewselector = ({ handleToolbar, toolbar, hasModel }) => {
  const { fullscreen } = useSnapshot(StoreScopedRequest);
  return (
    <div className="viewselector">
      <Row justify={["xsSpaceBetween"]}>
        <div className="viewselector-switch">
          <button
            className={`${toolbar === 0 && "active"}`}
            onClick={() => handleToolbar(0)}
          >
            <Pin color="white" />
          </button>
          {hasModel !== null && (
            <button
              className={`${toolbar === 1 && "active"}`}
              onClick={() => handleToolbar(1)}
            >
              <Model color="white" />
            </button>
          )}
        </div>
        <button onClick={() => setFullscreen()}>
          {fullscreen ? <ExpandLess color="white" /> : <Expand color="white" />}
        </button>
      </Row>
    </div>
  );
};
