// Imports
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { editScopedRequestForest, uploadZip } from "@apis-forest";
import {
  Button,
  CardModalForest,
  InputFileUpload,
  Select,
  Textarea,
  useToastContext,
} from "@components-common";
import {
  OLMap,
} from "@components-forest";
import { Modal } from "@modals";
import { StoreScopedRequestForest } from "@store-forest";

export const ModalRequestForest = ({ isModalOpen }) => {
  const { request } = useSnapshot(StoreScopedRequestForest);
  const addToast = useToastContext();

  const [formData, setFormData] = useState({
    status: "Pending",
    comment: "",
  });

  // Request Files initial state (used for delete also)
  const reqZipInit = {
    filename: "",
    files: { zip: null }
  };

  // Request zip file state
  const [reqZip, setZip] = useState(reqZipInit);

  const statusOptions = [
    { key: "Pending", value: "Pending", label: "Várakozik" },
    { key: "Available", value: "Available", label: "Teljesítve" },
    { key: "Denied", value: "Denied", label: "Elutasítva" },
  ];

  // Set initial state
  useEffect(() => {
    if (request.request_id) {
      setFormData({
        status: request.status,
        comment: request.comment === null ? "" : request.comment,
      });
    }
  }, [request]);

  // Handle input change
  const handleInput = (e) => {
    setFormData((prevFormData) => {
      const { name, value, type, checked } = e.target;
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  // Handle form submition
  const onSubmit = () => {
    const token = localStorage.getItem("userToken");
    let body = {
      token: token,
      id: request.request_id,
      status: formData.status,
      comment: formData.comment,
      resolved_address: request.resolved_addr,
      request_data: JSON.stringify(request.request_data)
    };
    // Send request to API endpoint
    console.log(body);
    editScopedRequestForest(body, addToast);
  };

  // Handle file input change
  const handleFileChange = (e) => {
    e.preventDefault();
    const files = e.target.files;
    
    setZip({
      filename: files[0].name,
      files: { zip: files[0] }
    });
  };

  // Handle file input delete
  const handleFileDelete = (e) => {
    e.preventDefault();
    setZip(reqZipInit);
  };

  // Empty all files
  const removeZipFile = () => {
    setZip(reqZipInit);
  };

  // Handle file uploads on form submit
  const handleUpload = () => {
    const token = localStorage.getItem("userToken");
    const requestID = request.request_id;

    // Create new form
    const formData = new FormData();
    
    // Add blob to form
    let zipPath = reqZip.files;
    formData.append("zip_file", zipPath.zip === null ? "" : zipPath.zip);

    let body = {
      token: token,
      id: requestID,
      formData: formData,
    };

    if (!zipPath.zip) {
      addToast(["error", "Nincsen zip csomag hozzáadva!"]);
    } else {
      uploadZip(body, addToast, removeZipFile);
    }
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      type="request"
      title={`ERD-${request.request_id}`}
      status={request.status}
      fluid
    >
      <main>
        <div className="modal__cards">
          <CardModalForest type="date" data={request.times} />
          <CardModalForest type="location" data={request.coordinates} />
          <CardModalForest type="request-type" data={request.order_details} />
          <CardModalForest type="user" data={[request.user, request.domain]} />
        </div>
        <div className="modal__view">
          <div id="ortomap" />
          <OLMap />
        </div>
      </main>

      <aside>
        <div className="modal__scroll">
          <div className="modal__form__fields">
            {formData.status !== "Denied" ? (
              <>
              <div className="modal__scroll">
                <InputFileUpload
                  label="Zip csomag"
                  name="zip"
                  placeholder="Zip hozzáadása"
                  accept={["zip"]}
                  handleChange={handleFileChange}
                  handleDelete={handleFileDelete}
                  files={reqZip}
                />
              </div>
              <div className="modal__actions">
                <Button onClickEvent={handleUpload} buttonType="small">
                  Fájl feltöltése
                </Button>
              </div>
              </>
            ) : (
              <Textarea
                label="Szöveges magyarázat"
                value={formData.comment}
                onChange={handleInput}
                name="comment"
              />
            )}
          </div>
        </div>
        <hr />
        <div className="modal__actions">
          <Select
            options={statusOptions}
            onChange={handleInput}
            name="status"
            selected={formData.status}
            value={formData.status}
          />
          <Button
            onClickEvent={onSubmit}
            buttonType={formData.status === "Denied" ? "danger" : ""}
          >
            {formData.status === "Denied" ? "Elutasítás" : "Mentés"}
          </Button>
        </div>
      </aside>
    </Modal>
  );
};
