// Imports
import { Row } from "@components-common";
import { IconsService } from "@icons";

export const CheckboxDomain = ({ name, label, type, checked, onChange, value }) => {
  return (
    <div className="checkbox__domain">
      <label name={name}>
        <input
          name={name}
          type="checkbox"
          onChange={onChange}
          checked={checked}
          value={value}
        />
        <Row justify={["xsSpaceBetween"]}>
          <div>
            <span className="checkbox__domain__checkmark"></span>
            {label}
          </div>
          <IconsService type={type} />
        </Row>
      </label>
    </div>
  );
};
