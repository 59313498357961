// Imports
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { editScopedRequest, postTerranCalculation } from "@apis-teto";
import { Button, CardModal, Select, useToastContext } from "@components-common";
import {
  ModalRequestDenied,
  ModalRequestFiles,
  ModelViewer,
  OLMap,
  Viewselector,
} from "@components-teto";
import { Modal } from "@modals";
import { StoreScopedRequest, setToolbar } from "@store-teto";

export const ModalRequest = ({ isModalOpen }) => {
  const { request, toolbar, fullscreen } = useSnapshot(StoreScopedRequest);
  const addToast = useToastContext();

  const [formData, setFormData] = useState({
    status: "Pending",
    comment: "",
  });

  const statusOptions = [
    { key: "Pending", value: "Pending", label: "Várakozik" },
    { key: "Available", value: "Available", label: "Teljesítve" },
    { key: "Denied", value: "Denied", label: "Elutasítva" },
  ];

  // Set initial state
  useEffect(() => {
    if (request.request_id) {
      setFormData({
        status: request.status,
        comment: request.comment,
      });
      setToolbar(request.model_json ? 1 : 0);
    }
  }, [request]);

  // Handle input change
  const handleInput = (e) => {
    setFormData((prevFormData) => {
      const { name, value, type, checked } = e.target;
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  // Set active toolbar tab
  const handleToolbar = (i) => {
    setToolbar(i);
  };

  // Handle form submition
  const onSubmit = () => {
    
    const token = localStorage.getItem("userToken");
    const body = {
      token: token,
      id: request.request_id,
      status: formData.status,
      comment: formData.comment,
    };
    
    // Send request to API endpoint
    editScopedRequest(body, addToast);

    // TERRÁN Roof Calculator POST call
    if(request.api.meta && formData.status === "Available" && request.model_json) {

      const calculationID = request.api.meta;
      const calculations = request.model_json.calculations;
      const views = ['topView', 'northView', 'eastView', 'southView', 'westView'];
      const images = {};
      views.forEach((view) => {
        images[view] = `https://res.cloudinary.com/envimap/image/upload/${request.request_id}/${view}.jpg`;
      });

      const terranBody = {
        roof_area: parseFloat(calculations.roof_area),
        avg_angle: parseFloat(calculations.avg_angle)*(Math.PI/180),
        horizontal_ridge_length: parseFloat(calculations.horizontal_ridge_length),
        edge_ridge_length: parseFloat(calculations.edge_ridge_length),
        gable_length: parseFloat(calculations.gable_length),
        drain_length: parseFloat(calculations.drain_length),
        vapa_length: parseFloat(calculations.vapa_length),
        images: images,
      };

      postTerranCalculation(calculationID, terranBody, addToast);
    }
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      type="request"
      title={`TET-${request.request_id}`}
      status={request.status}
      fluid
    >
      <main>
        <div className="modal__cards">
          <CardModal type="date" data={request.times} />
          <CardModal type="location" data={request.coordinates} />
          <CardModal type="request-type" data={request.order_details} />
          {request.user.id
            ? (<CardModal type="user" data={[request.user, request.domain]} />)
            : (<CardModal type="api" data={[request.api, request.domain]} />)
          }
        </div>
        <div className={`modal__view ${fullscreen ? "expanded" : ""}`}>
          <div id="ortomap" />
          <Viewselector
            handleToolbar={handleToolbar}
            toolbar={toolbar}
            hasModel={request.model_json}
          />
          {toolbar === 0 ? <OLMap /> : <ModelViewer />}
        </div>
      </main>

      <aside>
        {formData.status !== "Denied" ? (
          <ModalRequestFiles />
        ) : (
          <ModalRequestDenied handleInput={handleInput} formData={formData} />
        )}
        <hr />
        <div className="modal__actions">
          <Select
            options={statusOptions}
            onChange={handleInput}
            name="status"
            selected={formData.status}
            value={formData.status}
          />
          <Button
            onClickEvent={onSubmit}
            buttonType={formData.status === "Denied" ? "danger" : ""}
          >
            {formData.status === "Denied"
              ? "Elutasítás"
              : "Mentés"
            }
          </Button>
        </div>
      </aside>
    </Modal>
  );
};
