// Imports
import { urls } from "@apis-common";
import { getScopedRequest } from "@apis-teto";
import { CheckError } from "@utils-common";

// Upload files to specific request
export const uploadFilesInRequest = async (body, message, removeAllFiles) => {
  await fetch(urls.requestsFileUpload + body.id, {
    method: "POST",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
    body: body.formData,
  })
    .then((response) => CheckError(response, message))
    .then((response) => {
      console.log(response);
      message(["success", "Sikeres fáj feltöltés!"]);
      // Remove files
      removeAllFiles();
      // Refresh scoped request
      const takeScreenshots = true;
      getScopedRequest(body, message, takeScreenshots);
    })
    .catch((error) => {
      console.log(error);
    });
};
