// Imports
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { getPartnerRequests, getRequests } from "@apis-teto";
import {
  Table,
  TableSavedRequests,
  useMultiFilter,
  useToastContext,
} from "@components-common";
import { StoreRequestsList } from "@store-common";

export const SavedRequestsPage = () => {
  const snap = useSnapshot(StoreRequestsList);
  const addToast = useToastContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({});
  const filterableColums = ["id", "created_at", "resolved_addr"];
  const filteredData = useMultiFilter(
    snap,
    searchQuery,
    filters,
    filterableColums
  );

  // Fetch data
  useEffect(() => {
    let body = {
      token: localStorage.getItem("userToken"),
    };
    getRequests(body, addToast);
    getPartnerRequests(body);
  }, [addToast]);

  return (
    <Table
      title="Mentett"
      searchButtons={false}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      setFilters={setFilters}
      filters={filters}
    >
      <TableSavedRequests data={filteredData} expanded={true} />
    </Table>
  );
};
