// Imports
import { urls } from "@apis-common";
import { getPartnerRequests } from "@apis-teto";
import { SetScopedDomainPrevReqs } from "@store-common";
import { CheckError } from "@utils-common";

// Get users list
export const getRequests = async (body, message) => {
  await fetch(urls.requests, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      SetScopedDomainPrevReqs(actualData);
      // Call partner requests
      getPartnerRequests(body, actualData);
    })
    .catch((error) => console.log(error));
};
