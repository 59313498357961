import { transform } from "ol/proj";
import { register } from "ol/proj/proj4";
import proj4 from "proj4";

proj4.defs(
  "EPSG:23700",
  "+proj=somerc +lat_0=47.1443937222222 +lon_0=19.0485717777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=57.01,-69.97,-9.29,0,0,0,0 +units=m +no_defs +type=crs"
);
register(proj4);

export const downloadGeoJSON = (geoJSON, fileName) => {

  // Transform coordinates from EPSG:3857 to EPSG:23700
  const transformCoordinates = (coordinates) =>
    coordinates.map((point) => transform(point, "EPSG:3857", "EPSG:23700"));

  // Create a new GeoJSON object with transformed coordinates
  const transformedGeoJSON = {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: { name: "EPSG:23700" },
    },
    features: geoJSON.features.map((feature) => ({
      type: "Feature",
      geometry: {
        type: feature.geometry.type,
        coordinates: feature.geometry.coordinates.map(transformCoordinates),
      },
      properties: { ...geoJSON.properties },
    })),
  };

  // Create a data URI from the GeoJSON object
  const dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(transformedGeoJSON));

  // Create a temporary anchor element and click it
  const downloadAnchorNode = document.createElement("a");
  Object.assign(downloadAnchorNode, {
    href: dataStr,
    download: `${fileName}.geojson`,
  });

  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};