// Imports
import { createContext, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useSnapshot } from "valtio";
import { Container, ToastContextProvider } from "@components-common";
import { ModalManager } from "@modals";
import {
  Dashboard,
  DomainsPage,
  Error,
  ForgottenPasswordPage,
  LoginPage,
  RequestsPage,
  SavedRequestsPage,
  UsersPage,
} from "@pages";
import { SetUserLoggedIn, StoreLogin } from "@store-common";

export const ModalContext = createContext();

export const MainRouter = ({ children }) => {
  const { loggedIn } = useSnapshot(StoreLogin);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("registration");

  useEffect(() => {
    isModalOpen ? OpenModalFunction() : (document.body.style.overflow = "");
    return () => {};
  }, [isModalOpen]);

  const OpenModalFunction = () => {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (localStorage.getItem("userLoggedIn")) SetUserLoggedIn(true);
  }, [loggedIn]);

  return loggedIn ? (
    <ToastContextProvider>
      <ModalContext.Provider
        value={{ modalOpen: setIsModalOpen, modalType: setModalType, modalCurrentType: modalType }}
      >
        <ModalManager
          type={modalType}
          isModalOpen={isModalOpen}
          setIsOpen={isModalOpen}
        />
        <Router>
          {children}
          <Container>
            <Routes>
              <Route path="/">
                <Route index element={<Dashboard />} />
                <Route path="requests" element={<RequestsPage />} />
                <Route path="saved-requests" element={<SavedRequestsPage />} />
                <Route path="users" element={<UsersPage />} />
                <Route path="domains" element={<DomainsPage />} />
                <Route path="*" element={<Error />} />
              </Route>
            </Routes>
          </Container>
        </Router>
      </ModalContext.Provider>
    </ToastContextProvider>
  ) : (
    <ToastContextProvider>
      <Router>
        <Routes>
          <Route path="/">
            <Route index element={<LoginPage />} />
            <Route path="forgotten" element={<ForgottenPasswordPage />} />
            <Route path="*" element={<LoginPage />} />
          </Route>
        </Routes>
      </Router>
    </ToastContextProvider>
  );
};
