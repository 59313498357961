import { CheckboxDomain } from "@components-common";
import { possibleServices } from "@store-common";
import { getDomainService } from "@utils-common";

export const CheckboxServices = ({ formData, onChange }) => {
  return (
    <div className="checkbox-services">
      {possibleServices.map((service, index) => (
        <CheckboxDomain
          name={"available_services"}
          key={index}
          label={getDomainService(service)}
          type={service}
          value={service}
          checked={
            formData.available_services === undefined
              ? ""
              : formData.available_services.includes(service)
          }
          onChange={onChange}
        />
      ))}
    </div>
  );
};
