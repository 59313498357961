// Imports
import { urls } from "@apis-common";
import { SetScopedUser } from "@store-common";
import { CheckError } from "@utils-common";

// Get selected user
export const getScopedUser = async (body, message) => {
  await fetch(urls.userByID + body.id, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      SetScopedUser(actualData);
    })
    .catch((error) => {
      console.log(error);
    });
};
