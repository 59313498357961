import { proxy } from "valtio";

// Default states
export const StoreDomainsList = proxy([
  {
    domain_id: "",
    domain_name: "",
    company_name: "",
    company_postal: "",
    company_city: "",
    company_address: "",
    company_vat: "",
    on_trial: true,
    created_at: "",
    is_active: true,
    users: null,
    parent_id: null,
    is_super_domain: true,
    available_services: [],
  },
]);

// Set users list
export const SetDomainsList = (body) => {
  StoreDomainsList.length = 0; // Clear the array

  StoreDomainsList.push(
    ...body.map((domain) => ({
      domain_id: domain.domain_id,
      domain_name: domain.domain_name,
      company_name: domain.company_name,
      company_postal: domain.company_postal,
      company_city: domain.company_city,
      company_address: domain.company_address,
      company_vat: formatVat(domain.company_vat),
      users: domain.totalusers,
      on_trial: domain.on_trial,
      is_active: domain.is_active,
      created_at: formatRegistrationDate(domain.created_at),
      parent_id: domain.parent_id,
      is_super_domain: domain.is_super_domain,
      available_services: JSON.parse(domain.available_services),
    }))
  );
};

const formatVat = (unformattedVat) => {
  if (unformattedVat !== null) {
    const char = "-";
    const positionA = 8;
    const positionB = 10;

    const sliceUtility = (formattingDate, position, char) =>
      [
        formattingDate.slice(0, position),
        char,
        formattingDate.slice(position),
      ].join("");

    let preformatted = sliceUtility(unformattedVat, positionA, char);
    const formattedDate = sliceUtility(preformatted, positionB, char);
    return formattedDate;
  }
};

const formatRegistrationDate = (unformattedDate) => {
  let formattedDate = unformattedDate.slice(0, 10);
  formattedDate = formattedDate.replaceAll("-", ".");
  return formattedDate;
};
