//Imports
import { urls } from "@apis-common";
import { CheckError } from "@utils-common";

// Patch scoped domain
export const editScopedDomain = async (body, message, modalOpen) => {
  const detach_parent = body.parent_id === "0" ? true : false;
  const parent_id =
    (body.parent_id === "0") | (body.parent_id === 0) ? null : body.parent_id;
    
  await fetch(urls.domainByID + body.domain_id, {
    method: "PATCH",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      domain_name: body.domain_name,
      company_name: body.company_name,
      company_postal: body.company_postal,
      company_city: body.company_city,
      company_address: body.company_address,
      company_vat: body.company_vat,
      on_trial: body.package,
      parent_id: parent_id,
      detach_parrent: detach_parent,
      available_services: JSON.stringify(body.available_services),
    }),
  })
    .then((response) => CheckError(response, message))
    .then(() => {
      // Send success message to user
      message(["success", "Változtatások mentése sikeres."]);
      // Close modal
      modalOpen(false);
    })
    .catch((error) => {
      console.log(error);
    });
};
