import { proxy } from "valtio";

// Default states
export const SnapAdminData = proxy({
  adminName: "",
  adminRole: "",
});

// Set user old password
export const SetAdminName = (value) => (SnapAdminData.adminName = value);

// Set user new passwod
export const SetAdminRole = (value) => (SnapAdminData.adminRole = value);
