// Imports
import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import * as THREE from "three";
import { useSnapshot } from "valtio";
import StoreModel from "./StoreModel";

// POINTER
export const Pointer = ({ model, camera, control }) => {
  const { ready } = useSnapshot(StoreModel.model);
  const { poi, n, poiFaceIndex, pointerScaleFactor, snapMargin, visible, panelHovered } = useSnapshot(StoreModel.pointer);
  const { points } = useSnapshot(StoreModel.ruler);

  let raycaster = new THREE.Raycaster();

  function snapCursor(pos) {
    StoreModel.pointer.poi.copy(pos);
    StoreModel.pointer.snapped = true;
  }

  function checkSnapCursor(faceIndex, obj) {
    let tp = [ new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3() ];
    for (let i = 0; i < tp.length; i++) {
      tp[i].fromBufferAttribute(obj.geometry.attributes.position, faceIndex * 3 + i);
    }

    if (poi.distanceTo(tp[0]) < snapMargin) {
      snapCursor(tp[0]);
    } else if (poi.distanceTo(tp[1]) < snapMargin) {
      snapCursor(tp[1]);
    } else if (poi.distanceTo(tp[2]) < snapMargin) {
      snapCursor(tp[2]);
    } else if (points.length >= 3 && poi.distanceTo(points[0]) < snapMargin) {
      snapCursor(points[0]);
    } else {
      StoreModel.pointer.snapped = null;
    }
  }

  useFrame(({ mouse }) => {

    raycaster.setFromCamera( mouse, camera.current );
    
    if (ready) {
      const intersects = raycaster.intersectObject( model.current );

      //Scale cursor
      StoreModel.pointer.pointerScale = control.current.getDistance() * pointerScaleFactor;

      //Check intersection and update pointer
      if (intersects.length > 0 && visible && !panelHovered) {
        let i0 = intersects[0];
        let obj = i0.object;

        //Get pointed poiLayer
        StoreModel.pointer.poiLayer = obj.geometry.userData.layer;

        //Get pointed poiType
        StoreModel.pointer.poiType = obj.geometry.userData.type;

        //Get pointed sideIndex
        if (obj.geometry.userData.type !== "edges") {
          const feature = StoreModel.model[obj.geometry.userData.type].filter(e => e.properties.LAYER === obj.geometry.userData.layer);
          feature.forEach((f) => {
            if(f.faceIndexes.includes(i0.faceIndex)){
              StoreModel.pointer.poiUID = f.uID;
            }
          });
        } else {
          StoreModel.pointer.poiUID = obj.geometry.userData.uID;
        }

        //Get pointer faceIndex
        StoreModel.pointer.poiFaceIndex = i0.faceIndex;

        //Position the pointer
        StoreModel.pointer.poi.copy(i0.point);

        //Check snapping if current tool is ruler
        StoreModel.tool.currentTool === 1 && checkSnapCursor(poiFaceIndex, obj);

        //Rotate the pointer
        StoreModel.pointer.n.copy(i0.face.normal);
        StoreModel.pointer.n.transformDirection( obj.matrixWorld );
        StoreModel.pointer.la.copy(poi).add(n);
      } else {
        StoreModel.pointer.poiUID = null;
      }
    }
  });

  return (
    <mesh>
      <Point/>
    </mesh>
  );
};

const Point = () => {
  const { poi, la, visible, snapped, pointerScale, poiType } = useSnapshot(StoreModel.pointer);
  const { currentView } = useSnapshot(StoreModel.view);

  const pointRef = useRef();

  useFrame(() => {
    pointRef.current.position.copy(poi);
    pointRef.current.lookAt(la);
    pointRef.current.visible = visible;
  });

  return(
    <mesh ref={pointRef}>
      <mesh visible={poiType !== "edges"}>
        <torusGeometry attach="geometry" args={[snapped ? pointerScale*0.25 : pointerScale*0.2, pointerScale*0.01, 10, 60]} />
        <meshBasicMaterial attach="material" color={snapped ? "#00FF7F" : currentView === 2 ? "#A1A1AA" : "#FFF"} />
      </mesh>
      <mesh>
        <sphereGeometry attach="geometry" args={[pointerScale*0.06, 24, 16]} />
        <meshBasicMaterial attach="material" color={"#00FF7F"} />
      </mesh>
    </mesh>
  );
};
