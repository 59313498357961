import { proxy } from "valtio";
import { formatDate, getBuildingFeature, getTMinus } from "@utils-common";

const requestInit = {
  request_id: null,
  status: "",
  status_initial: "",
  service: "",
  order_details: {
    building_features: "",
    building_type: "",
    ha: 0,
  },
  request_data: {
    polygons: [],
    package: 0,
    total_area: 0,
    available_from: "",
    available_till: "",
    survey_date: "",
    status: "",
  },
  times: {
    created_at: "",
    updated_at: "",
    tminus: "",
  },
  coordinates: {
    lat: 0,
    lng: 0,
  },
  resolved_addr: "",
  domain: {
    domain_id: "",
    domain_name: "",
  },
  user: {
    id: "",
    email: "",
    last_name: "",
    first_name: "",
  },
  comment: "",
  model_json: null,
  pcd_file: null,
};

const packages = ["Alap", "Prémium", "Extra"];

// STORE
export const StoreScopedRequestForest = proxy({
  request: requestInit,
  fullscreen: false,
});

// Set scoped request
export const SetScopedRequest = (request) => {
  const { Map, Domain, User, model_json } = request;

  const request_data = JSON.parse(Map.request_data);

  StoreScopedRequestForest.request = {
    request_id: Map.request_id,
    status: Map.status,
    service: Map.service,
    order_details: {
      building_features: getBuildingFeature(Map.building_features),
      building_type: packages[request_data.package],
      ha: request_data.total_area,
    },
    request_data: { ...request_data, status: Map.status },
    times: {
      created_at: formatDate(Map.created_at),
      updated_at: formatDate(Map.updated_at),
      tminus: Map.status === "Pending" && getTMinus(Map.created_at),
    },
    coordinates: {
      lat: Map.lat,
      lng: Map.lon,
    },
    resolved_addr: Map.resolved_addr,
    domain: {
      domain_id: Domain.domain_id,
      domain_name: Domain.domain_name,
    },
    user: {
      id: User.id,
      email: User.email,
      last_name: User.last_name,
      first_name: User.first_name,
    },
    comment: Map.comment,
    model_json: model_json,
    pcd_file: null,
  };
};

// Clear scoped request
export const ClearScopedRequestForest = () => {
  StoreScopedRequestForest.request = requestInit;
};

// Set scoped request status
export const setScopedRequestStatus = (status) => {
  const { request } = StoreScopedRequestForest;

  StoreScopedRequestForest.request.status = status
    ? "Available"
    : request.status_initial || "";
};

// Set expanded
export const setFullscreen = () => {
  StoreScopedRequestForest.fullscreen = !StoreScopedRequestForest.fullscreen;
};
