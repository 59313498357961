// Imports
import { useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { setScreenshotStage, setTakeScreenshots, StoreScopedRequest } from "@store-teto";
import StoreModel from "./StoreModel";

// CloudinaryURL
const cloudinaryURL = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_ENV}/image/upload`;

// Camera views with position values
export const screenshotViews = [
  { name: "topView",    values: [0, 3, 0.01] },
  { name: "northView",  values: [0, 1, -2] },
  { name: "eastView",   values: [2, 1, 0] },
  { name: "southView",  values: [0, 1, 2] },
  { name: "westView",   values: [-2, 1, 0] },
];

// SCREENSHOT TOOL
export const ScreenshotTool = () => {
  const { ready, bounds } = useSnapshot(StoreModel.model);
  const { request_id } = useSnapshot(StoreScopedRequest.request);
  const { gl, camera } = useThree();
  const [ screenshots, setScreenshots ] = useState([]);
  const [ currentStage, setCurrentStage ] = useState(0);
  const [ activeCamera, setActiveCamera ] = useState(0);
  const [ uploadData, setUploadData ] = useState([]);
  const [ imagesUploaded, setImagesUploaded ] = useState(0);

  // Change View
  const setCamera = (index) => {
    // Set screenshot stage (the stage displayed for the admin)
    setScreenshotStage(index + 1);
    // Get the camera values from screenshotViews
    const [ posX, posY, posZ ] = screenshotViews[index].values;
    // Set camera position to the new values
    camera.position.set(bounds.max*posX, bounds.z*posY, bounds.max*posZ);
    // Change activeCamera value
    setActiveCamera(activeCamera + 1);
  };

  // Take a screenshot
  const takeScreenshot = (index) => {
    // Get the canvas
    const canvas = gl.domElement;
    // Create a jpeg file from canvas
    canvas.toBlob((blob) => {
      const file = new File([blob], `${screenshotViews[index].name}.jpg`, { type: 'image/jpeg' });
      setScreenshots((prevScreenshots) => [
        ...prevScreenshots,
        { name: screenshotViews[index].name, file: file },
      ]);
    }, 'image/jpeg', 0.8);
  };

  // Upload screenshot to Cloudinary
  const createUploadData = (index) => {
      // Create FormData with Cloudinary parameters
      const formData = new FormData();
      formData.append("file", screenshots[index].file);
      formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);
      formData.append("public_id", `${request_id}/${screenshots[index].name}`);
      // Add FormData to the Upload Data
      setUploadData((prevUploadData) => [
        ...prevUploadData,
        formData
      ]);
      // Add one to currentStage to go to the next view
      setCurrentStage(currentStage + 1);
  };

  // Handle uploading loop
  const handleUpload = () => {
    // Set screenshot stage to upload value
    setScreenshotStage(6);
    // Initiate upload in a loop
    uploadData.forEach((data, i) => {
      uploadScreenshot(data, i);
    });
  };

  // Upload screenshot to Cloudinary
  const uploadScreenshot = async (body, index) => {
    await fetch(cloudinaryURL, {
      method: "POST",
      body: body,
    })
      .then((response) => {
        console.log(response);
        if(response.status === 200) {
          setImagesUploaded(index + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Clean up after all finished
  const cleanUp = () => {
    setScreenshotStage(0);
    setTakeScreenshots(false);
  };

  useEffect(() => {
    if (!ready) return;
    const timer = setTimeout(() => {
      if (currentStage < screenshotViews.length){
        if(activeCamera === currentStage) {
          setCamera(currentStage);
        } else if (screenshots.length === currentStage) {
          takeScreenshot(currentStage);
        } else {
          createUploadData(currentStage);
        }
      } else if (!imagesUploaded) {
        handleUpload();
      } else if (imagesUploaded) {
        cleanUp();
      }
    }, 200);
    return () => clearTimeout(timer);
  }, [ready, activeCamera, screenshots, currentStage, imagesUploaded]);

  return null;
};