// Imports
import { urls } from "@apis-common";
import { CheckError } from "@utils-common";

// Upload files to specific request
export const uploadZip = async (body, message, removeZipFile) => {
  await fetch(urls.uploadZip + body.id + "/zip", {
    method: "POST",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
    body: body.formData,
  })
    .then((response) => CheckError(response, message))
    .then((response) => {
      console.log(response);
      message(["success", "Sikeres fáj feltöltés!"]);
      // Remove zip file from input
      removeZipFile();
    })
    .catch((error) => {
      console.log(error);
    });
};
