// Imports
import { useState, useEffect } from "react";
import { useSnapshot } from "valtio";
import { Camera, Service } from "@icons";
import { StoreScopedRequest } from "@store-teto";
import { screenshotViews } from "../ScreenshotTool";

// VIEWBAR
const ScreenshotOverlay = () => {
  return (
    <div className="screenshotoverlay">
      <ScreenshotProgress />
    </div>
  );
};

const ScreenshotProgress = () => {
  const { screenshotStage } = useSnapshot(StoreScopedRequest.request);
  const [progress, setProgress] = useState(0);
  const spinDuration = 500;

  let props = {
    size: 200,
    strokeWidth: 8,
  };

  const center = props.size / 2,
        radius = center - props.strokeWidth,
        dashArray = 2 * Math.PI * radius,
        dashOffset = dashArray * ((100 - progress) / 100);

  useEffect(() => {
    if (screenshotStage < 6)  {
      setProgress(screenshotStage*20);
    } else {
      let spinTimeout = setTimeout(() => {
        if(progress < 100) {
          setProgress(progress + 1);
        } else {
          setProgress(0);
        }
      }, spinDuration/100);
      return () => {
        clearTimeout(spinTimeout);
      };
    }
  }, [screenshotStage, progress]);

  if (!screenshotStage) return;
  return(
    <div className="screenshotoverlay__progress">
      <div className="screenshotoverlay__progress__status">
        {screenshotStage < 6
          ? (<Camera color="white"/>)
          : (<Service color="white"/>)
        }
        <div className="screenshotoverlay__progress__status-step">
          {screenshotStage < 6
            ? (
              <>
                <span className="span--bold">Fotók generálása</span>
                <span>{screenshotViews[screenshotStage-1].name}</span>
              </>
            ) : (
              <span className="span--bold">Fotók feltöltése</span>
            )
          }
        </div>
      </div>
      <div className="screenshotoverlay__progress__indicator">
        <svg>
          <circle
            className="track"
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={props.strokeWidth}
          />
          <circle
            className={`indicator ${screenshotStage < 6 && "animated"}`}
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={props.strokeWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default ScreenshotOverlay;