// Imports
import { Fill, Stroke, Style, Text } from "ol/style";

// Styles
export const style = new Style({
  fill: new Fill({
    color: "rgba(0, 255, 127, 0.5)",
  }),
  stroke: new Stroke({
    color: "#00FF7F",
    width: 3,
  }),
});
export const segmentStyle = new Style({
  text: new Text({
    fill: new Fill({
      color: "#FFFFFF",
    }),
    font: "bold 14px IBM Plex Sans, Calibri",
    padding: [3, 3, 3, 3],
    textBaseline: "bottom",
    offsetY: -15,
  }),
});
export const pendingStyle = new Style({
  fill: new Fill({
    color: "#f5f53d80",
  }),
  stroke: new Stroke({
    color: "#F5F53D",
    width: 3,
  }),
});
export const editingStyle = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.5)",
  }),
  stroke: new Stroke({
    color: "#FFFFFF",
    width: 3,
  }),
});

// Add style function
export const prevStyleFunction = (feature) => {
  const featureStatus = feature.get("status");
  let styles;
  
  if (featureStatus === "Available") styles = [style];
  if (featureStatus === "Pending") styles = [pendingStyle];
  if (featureStatus === "Archived") styles = [editingStyle];
  if (featureStatus === "Saved") styles = [editingStyle];

  return styles;
};
