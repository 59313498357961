import { proxy } from "valtio";

// Default states
export const StoreLogin = proxy({
  loggedIn: false,
  loginEmail: "",
  loginPassword: "",
  rememberMe: false,
  forgottenEmail: "",
});

// Set user logged in
export const SetUserLoggedIn = (value) => (StoreLogin.loggedIn = value);

// Set user login email
export const SetUserEmail = (email) => (StoreLogin.loginEmail = email);

// Set user login password
export const SetUserPassword = (password) =>
  (StoreLogin.loginPassword = password);

// Remember user login data if checkbox is ticked
export const SetRememberMe = (value) => {
  StoreLogin.rememberMe = value;
};

// Set user forgotten email
export const SetUserForgottenEmail = (email) =>
  (StoreLogin.forgottenEmail = email);
