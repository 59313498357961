// Imports
import { SadPin } from "@icons";

export const TableNoResults = ({ icon, text }) => {
  return (
    <div className="datatable__noresults">
      <div className="datatable__noresults__icon">{icon || <SadPin />}</div>
      <h5>{text}</h5>
    </div>
  );
};
