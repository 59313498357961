// Imports
import { Plane, Edges } from "@react-three/drei";
import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { useSnapshot } from "valtio";
import StoreModel from "../StoreModel";

// Define EPSG:23700 used by the building's JSON data
proj4.defs('EPSG:23700',
  '+proj=somerc +lat_0=47.1443937222222 +lon_0=19.0485717777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=57.01,-69.97,-9.29,0,0,0,0 +units=m +no_defs +type=crs');
register(proj4);


// BASE PLANE
const BasePlane = () => {
  const { loaded, bounds, basePlaneMargin } = useSnapshot(StoreModel.model);
  const { currentView, ortomapChangeTrack, ortoAvailable } = useSnapshot(StoreModel.view);

  const basePlaneRef = useRef();
  const textureRef = useRef();
  const mapCanvasRef = useRef(document.createElement("canvas"));
  const [canvas, setCanvas] = useState(null);
  const [canvasSize, setCanvasSize] = useState(0);

  useEffect(() => {
    if (!bounds || !basePlaneMargin) return;
    setCanvasSize(bounds.max + (basePlaneMargin * 2));
  }, [bounds, basePlaneMargin]);

  useEffect(() => {
    if (ortomapChangeTrack === 0 || !ortoAvailable) return;

    // Defaults
    const size = [1500,1500];
    const projectionZoomFactor = 150/canvasSize;

    // Scale canvas
    mapCanvasRef.current.width = size[0];
    mapCanvasRef.current.height = size[1];

    // Get context
    const mapContext = mapCanvasRef.current.getContext("2d");
    
    // Copy map canvas to texture canvas
    Array.prototype.forEach.call(
      document.querySelectorAll("#ortomap .ol-layer canvas"),
      function (canvas) {
        if (canvas.width > 0) {
          mapContext.drawImage(
            canvas,
            size[0]*-((projectionZoomFactor/2)-0.5),
            size[1]*-((projectionZoomFactor/2)-0.5),
            size[0]*projectionZoomFactor,
            size[1]*projectionZoomFactor,
          );
        }
      }
    );

    // Set canvas
    setCanvas(mapCanvasRef.current);
  }, [ortomapChangeTrack, ortoAvailable, canvasSize]);

  if (loaded && ortomapChangeTrack !== 0 && ortoAvailable) {
    return (
      <Plane
        visible={currentView === 1}
        ref={basePlaneRef}
        args={[canvasSize, canvasSize]}
        rotation={[Math.PI / -2, 0, 0]}
        position={[bounds.xMid, -0.01, bounds.zMid]}
      >
        <meshBasicMaterial
          side={THREE.DoubleSide}
        >
          <canvasTexture
            ref={textureRef}
            attach="map"
            image={canvas}
            needsUpdate={currentView === 1}
          />
        </meshBasicMaterial>
        <Edges color={"#EEEEEE"} />
      </Plane>
    );
  }
};

export default BasePlane;