// Imports
import { urls } from "@apis-common";
import { SetRequestsList } from "@store-common";
import { CheckError } from "@utils-common";

// Get users list
export const getPartnerRequests = async (body, requests) => {
  await fetch(urls.partnerRequests, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response))
    .then((actualData) => {
      // If the first array is empty, ignore that
      if (requests !== undefined) {
        if (requests.length !== 0) {
          // Create new array (partner and other requests)
          const allRequest = requests.concat(actualData);
          // Create new array in store with all requests
          SetRequestsList(allRequest);
        } else {
          SetRequestsList(actualData);
        }
      }
    })
    .catch((error) => console.log(error));
};
