//Imports
import { urls } from "@apis-common";
import { CheckError } from "@utils-common";

// Generate new password for admin
export const newPass = async (
  body,
  message,
  SetNewPass,
  SetOldPass,
  modalOpen
) => {
  await fetch(urls.newPass, {
    method: "PATCH",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      old_password: body.old_password,
      new_password: body.new_password,
    }),
  })
    .then((response) =>
      CheckError(response, message, "Téves az aktuális jelszó!")
    )
    .then(() => {
      message(["success", "Sikeres jelszóváltoztatás"]);
      SetNewPass("");
      SetOldPass("");
      modalOpen(false);
    })
    .catch((error) => {
      console.log(error);
    });
};
