// Imports
import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getScopedRequestForest } from "@apis-forest";
import { getScopedRequestGeostore } from "@apis-geostore";
import { getScopedRequest } from "@apis-teto";
import {
  Badge,
  ModalContext,
  TableLoader,
  TableNoResults,
  columWidth,
  paginationComponentOptions,
  useToastContext,
} from "@components-common";
import { IconsService } from "@icons";
import { formatAddress } from "@utils-common";

export const TableRequests = ({ data, expanded }) => {
  const [loading, setLoading] = useState(true);
  const { modalOpen, modalType } = useContext(ModalContext);
  const addToast = useToastContext();
  const [filteredData, setFilteredData] = useState(data);

  // Custom sorting for TMinus
  const customSortTMinus = (rowA, rowB) => {
    const a = rowA.tminus ? rowA.tminus.sec : 9e9;
    const b = rowB.tminus ? rowB.tminus.sec : 9e9;

    return a > b ? 1 : -1;
  };

  // Colums
  const colums = [
    {
      id: "tminus",
      name: "T-",
      selector: (row) => row.tminus && row.tminus.sec,
      cell: (row) =>
        row.tminus ? (
          <div className="badge-tminus">{row.tminus.hours}ó</div>
        ) : null,
      sortable: true,
      sortFunction: customSortTMinus,
      width: columWidth.dateCount,
      center: true,
      conditionalCellStyles: [
        {
          when: (row) =>
            row.tminus && row.tminus.hours <= 36 && row.tminus.hours > 12,
          style: { color: "#FA7A49" },
        },
        {
          when: (row) => row.tminus && row.tminus.hours <= 12,
          style: { color: "#FF0055" },
        },
      ],
    },
    {
      name: "Dátum",
      selector: (row) => row.created_at,
      sortable: true,
      width: columWidth.dateTime,
    },
    {
      name: "ID",
      selector: (row) =>
        row.service === "Solar"
          ? `TET-${row.id}`
          : row.service === "Forest"
          ? `ERD-${row.id}`
          : `ENV-${row.id}`,
      sortable: true,
      width: columWidth.large,
      omit: !expanded,
    },
    {
      name: "Szolgáltatás",
      selector: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconsService type={row.service} />
          <span style={{ marginLeft: ".5rem", padding: ".5rem 0" }}>
            {row.service === "Solar"
              ? "Envimap Tető"
              : row.service === "Forest"
              ? "Envimap Erdő"
              : "Téradatbank"}
          </span>
        </div>
      ),
      sortable: true,
      width: columWidth.large,
    },
    {
      name: "Domain",
      selector: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "1rem", padding: ".5rem 0" }}>
            {row.domain.domain_name}{" "}
          </span>
          {row.partner && <Badge status={"API"} type="requests" />}
        </div>
      ),
      sortable: true,
      width: columWidth.large,
      hide: "lg",
    },
    {
      name: "Cím",
      selector: (row) => formatAddress(row.resolved_addr),
      sortable: true,
      width: columWidth.xl,
    },
    {
      name: "Státusz",
      selector: (row) => <Badge status={row.status} type="requests" />,
      width: columWidth.medium,
      omit: !expanded,
    },
  ];

  // Loading state handling
  useEffect(() => {
    if (data.length >= 1) {
      setLoading(false);
    }
  }, [data]);

  // Filter data
  useEffect(() => {
    if (data.length >= 1) {
      const filtered = data.filter((item) => item.status !== "Saved");
      setFilteredData(filtered);
    }
  }, [data]);

  // Modal open function
  const openModalWithContent = (type, id, service) => {
    let body = {
      token: localStorage.getItem("userToken"),
      id: id,
    };

    if (service === "Solar") {
      getScopedRequest(body, addToast);
    }
    if (service === "Forest") {
      getScopedRequestForest(body, addToast);
    }
    if (service === "GeoStore") {
      getScopedRequestGeostore(body, addToast);
    }
    modalOpen(true);
    modalType(type);
  };
  return (
    <DataTable
      data={filteredData}
      columns={colums}
      onRowClicked={(row) => {
        if (row.service === "Solar")
          openModalWithContent("requests", row.id, row.service);
        if (row.service === "Forest")
          openModalWithContent("requestsForest", row.id, row.service);
        if (row.service === "GeoStore")
          openModalWithContent("requestsGeostore", row.id, row.service);
      }}
      progressPending={loading}
      progressComponent={<TableLoader />}
      defaultSortFieldId="tminus"
      noDataComponent={
        <TableNoResults text="Nem található a keresésnek megfelelő igény!" />
      }
      pagination={expanded}
      paginationPerPage={50}
      paginationComponentOptions={paginationComponentOptions}
      responsive
    />
  );
};
