export const BadgePoint = ({ status }) => {
  let color;
    switch (true) {
    case status === "Pending":
      color = "yellow";
      break;
    case status === "Available":
      color = "green";
      break;
    case status === "Denied":
      color = "red";
      break;
      case status === "InstantPurchase":
        color = "blue";
        break;
    default:
      break;
  }
  return <span className={`badge__point ${color}`}></span>;
};

