// Imports
import { urls } from "@apis-common";
import { getScopedRequest } from "@apis-teto";
import { setScopedRequestStatus } from "@store-teto";
import { CheckError } from "@utils-common";

// Edit scoped request
export const editScopedRequest = async (body, message) => {
  await fetch(urls.requests + body.id, {
    method: "PATCH",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      status: body.status,
      comment: body.comment,
    }),
  })
    .then((response) => CheckError(response, message))
    .then(() => {
      setScopedRequestStatus(body.status);
      // Set message is denied is successfully
      if (body.status === "Denied") message(["success", "Sikeres elutasítás!"]);
      // Refresh scoped user
      getScopedRequest(body, message);
    })
    .catch((error) => {
      console.log(error);
    });
};
