// Imports
import { urls } from "@apis-common";
import { CheckError } from "@utils-common";

// Delete scoped user
export const deleteScopedDomain = async (body, message, modalOpen) => {
  await fetch(urls.domainByID + body.id, {
    method: "DELETE",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
    mode: "cors",
  })
    .then((response) => CheckError(response, message))
    .then(() => {
      modalOpen(false);
      message(["success", "Domain törölve!"]);
    })
    .catch((error) => {
      console.log(error);
    });
};
