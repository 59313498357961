export const ModalLVStats = ({requests, date, type}) => {

  // Calculate user/domain active days
  const activeTime = Date.parse(date) - Date.parse(new Date());
  const activeDays = Math.floor(activeTime/(1000*60*60*24));

  // Calculate the lifetime value from active days and requests number
  const value = ((requests / Math.abs(activeDays)) * 10).toFixed(1);

  return (
    <div className="modal__requests-stats">
      <div>
        <h3>{requests}</h3>
        <span>Igények</span>
      </div>
      <div>
        <h3>{value}</h3>
        <span>{type === "user" ? "CLV" : "DLV"}</span>
      </div>
    </div>
  );
};
