// Imports
import { urls } from "@apis-common";
import { SetScopedUserNewDomain } from "@store-common";
import { CheckError } from "@utils-common";

// Edit scoped user domain
export const editScopedUserDomain = async (body, message) => {
  await fetch(urls.userByID + body.id + "/domain", {
    method: "PATCH",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      domain_id: body.new_domain_id,
    }),
  })
    .then((response) =>
      CheckError(response, message, "Sikertelen domain lekérés")
    )
    .then(() => {
      SetScopedUserNewDomain(body.new_domain_id);
    })
    .catch((error) => {
      console.log(error);
    });
};
