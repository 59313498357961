// Imports
import TileLayer from "ol/layer/Tile";
import { transformExtent } from "ol/proj";
import { register } from "ol/proj/proj4";
import XYZ from "ol/source/XYZ";
import proj4 from "proj4";

// EPSG:23700 used by the availabilityLayer JSON data
proj4.defs(
  "EPSG:23700",
  "+proj=somerc +lat_0=47.1443937222222 +lon_0=19.0485717777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=57.01,-69.97,-9.29,0,0,0,0 +units=m +no_defs +type=crs"
);
register(proj4);

// OSM BACKGROUND LAYER
export const backgroundLayerForest = new TileLayer({
  source: new XYZ({
    url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    maxZoom: 19,
    crossOrigin: "Anonymous",
  }),
  name: "backgroundLayer",
  className: "bw-layer",
  opacity: 0.6,
});

// GM SATALITE LAYER
export const satalaiteLayerForest = new TileLayer({
  source: new XYZ({
    url: "http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
  }),
  name: "satalaiteLayer",
});

// ORTO LAYERS
export const ortoLayer = new TileLayer({
  source: new XYZ({
    url: "https://tileserver{1-8}.envimap.hu/Full_Merge_230404/{z}/{x}/{y}",
    crossOrigin: "Anonymous",
    minZoom: 8,
    maxZoom: 18,
  }),
  name: "topoOrtoLayer",
  extent: transformExtent(
    [16.380969, 46.050858, 22.970843, 48.436549],
    "EPSG:4326",
    "EPSG:3857"
  ),
});
