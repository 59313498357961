// Imports
import {
  ModalDomain,
  ModalDomainNew,
  ModalProfile,
  ModalRequest,
  ModalRequestForest,
  ModalRequestForestSaved,
  ModalRequestGeostore,
  ModalUser,
  ModalUsersDropDown,
} from "@modals";
import { SetModalType } from "@store-common";

export const ModalManager = ({ isModalOpen, type }) => {
  switch (true) {
    case type === "requests":
      SetModalType("requests");
      return <ModalRequest isModalOpen={isModalOpen} />;
    case type === "requestsForest":
      SetModalType("requestsForest");
      return <ModalRequestForest isModalOpen={isModalOpen} />;
    case type === "requestsForestSaved":
      SetModalType("requestsForestSaved");
      return <ModalRequestForestSaved isModalOpen={isModalOpen} />;
    case type === "users":
      SetModalType("users");
      return <ModalUser isModalOpen={isModalOpen} />;
    case type === "domain":
      SetModalType("domain");
      return <ModalDomain isModalOpen={isModalOpen} />;
    case type === "domainNew":
      return <ModalDomainNew isModalOpen={isModalOpen} />;
    case type === "profile":
      return <ModalProfile isModalOpen={isModalOpen} />;
    case type === "usersDropDown":
      return <ModalUsersDropDown isModalOpen={isModalOpen} />;
    case type === "requestsGeostore":
      SetModalType("requestsGeostore");
      return <ModalRequestGeostore isModalOpen={isModalOpen} />;
    default:
      break;
  }
};
