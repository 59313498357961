import { BadgePoint } from "@components-common";

export const CardModalRequestsGeostore = ({ text, status = "Pending" }) => {
  return (
    <div className="card__request">
        <BadgePoint status={status ? 'Available' : 'InstantPurchase'} />
        <h5>{text}</h5>
    </div>
  );
};
 