// Check error
export const CheckError = (
  response,
  message,
  messageText = "Valami nincsen rendben..."
) => {
  if (response.status === 200) {
    return response.json();
  } else {
    // Send error message to user
    message(["error", messageText]);
    throw Error(response.statusText);
  }
};
