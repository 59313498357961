// Imports
import { urls } from "@apis-common";
import { SetScopedUserPrevReqs } from "@store-common";
import { CheckError } from "@utils-common";

// Get selected user prvious requests
export const getScopedUserPrevReqs = async (body) => {
  await fetch(urls.requestByDomain + body.domainID, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response))
    .then((actualData) => {
      let filteredList = actualData.filter(
        (element) => element.user.id === body.userID
      );
      SetScopedUserPrevReqs(filteredList);
    })
    .catch((error) => {
      console.log(error);
    });
};
