// Imports
import { useSnapshot } from "valtio";
import { Cursor, Ruler } from "@icons";
import StoreModel, { setCurrentTool } from "../StoreModel";


// TOOLBAR
const Toolbar = () => {
  return (
    <ul className="toolbar">
      <CursorTool/>
      <RulerTool/>
    </ul>
  );
};

const CursorTool = () => {
  const { currentTool, tools } = useSnapshot(StoreModel.tool);
  const { shiftPressed } = useSnapshot(StoreModel.cursor);

  return(
    <li 
      className={currentTool === 0 ? "active" : ""}
      title={tools[0]}
      onClick={(e) => {
        e.stopPropagation();
        setCurrentTool(0);
      }}
    >
      <Cursor
        multi={shiftPressed}
        color={currentTool === 0 ? null : "white"}
      />
    </li>
  );
};

const RulerTool = () => {
  const { currentTool, tools } = useSnapshot(StoreModel.tool);

  return(
    <li 
      className={currentTool === 1 ? "active" : ""}
      title={tools[1]}
      onClick={(e) => {
        e.stopPropagation();
        setCurrentTool(1);
      }}
    >
      <Ruler
        color={currentTool === 1 ? null : "white"}
      />
    </li>
  );
};

export default Toolbar;