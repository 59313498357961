// Component imports
import { Textarea } from "@components-common";

export const ModalRequestDenied = ({ handleInput, formData }) => {
  return (
    <div className="modal__form__fields">
      <Textarea
        label="Szöveges magyarázat"
        value={formData.comment}
        onChange={handleInput}
        name="comment"
      />
    </div>
  );
};
