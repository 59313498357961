// Imports
import { urls } from "@apis-common";
import { CheckError } from "@utils-common";

// Convert user to admin or admin to user
export const convertUserRole = async (body, message) => {
  await fetch(`${urls.convertUser}${body.id}/to_${body.value}`, {
    method: "PATCH",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response, message))
    .catch((error) => console.log(error));
};
