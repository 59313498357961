//Imports
import { urls } from "@apis-common";
import { SetAdminName, SetAdminRole } from "@store-common";
import { CheckError } from "@utils-common";

// Get admin information
export const getAdmin = async (body, message) => {
  await fetch(urls.profile, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      // Set admin name
      SetAdminName(actualData.full_name);

      // Set admin role
      SetAdminRole(actualData.role);
    })
    .catch((error) => {
      console.log(error);
    });
};
