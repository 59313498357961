// Imports
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ContainerFluid } from "@components-common";
import {
  ArrowDropDown,
  ArrowForward,
  Close,
  Hamburger,
  Logo,
  User,
} from "@icons";
import { SetUserLoggedIn } from "@store-common";

const NavLinks = [
  {
    text: "Dashboard",
    link: "/",
  },
  {
    text: "Igények",
    link: "/requests",
  },
  {
    text: "Mentett",
    link: "/saved-requests",
  },
  {
    text: "Felhasználók",
    link: "/users",
  },
  {
    text: "Domainek",
    link: "/domains",
  },
];

const ProfileMenu = () => {
  const handleLogout = (e) => {
    e.preventDefault();
    // Save state to localstorage
    localStorage.clear();

    // Reload window
    window.location.reload();
    SetUserLoggedIn(false);
  };

  return (
    <div className="header__content__profile-menu">
      <ul>
        <li>
          <a href="/" onClick={(e) => handleLogout(e)}>
            Kijelentkezés
            <ArrowForward />
          </a>
        </li>
      </ul>
    </div>
  );
};

const NavbarOverlay = ({ menuOpen, setMenuOpen }) => {
  return (
    <div
      className={
        menuOpen
          ? "header__mobile-overlay header__mobile-overlay-active"
          : "header__mobile-overlay"
      }
      onClick={() => {
        setMenuOpen(!menuOpen);
      }}
    ></div>
  );
};

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  useEffect(() => {
    menuOpen ? OpenModalFunction() : (document.body.style.overflow = "");
    return () => {};
  }, [menuOpen]);

  const OpenModalFunction = () => {
    document.body.style.overflow = "hidden";
  };
  return (
    <ContainerFluid>
      <div className="header__content">
        <NavbarOverlay menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <div className="header__content__left">
          <NavLink to="/" className="header__content__left__logo">
            <Logo />
          </NavLink>
          <h3 className="header__content__left__title">Service Admin</h3>
        </div>
        <nav
          className={`header__content__nav ${
            menuOpen ? "header__content__nav-menu-open" : ""
          }`}
        >
          <ul>
            <div className="header__content__nav-close">
              <button
                className="header__content__nav-close-button"
                onClick={() => {
                  setMenuOpen(!menuOpen);
                }}
              >
                <Close />
              </button>
            </div>
            {NavLinks.map((element, index) => {
              return (
                <li key={index}>
                  <NavLink to={element.link} onClick={() => setMenuOpen(false)}>
                    {element.text}
                  </NavLink>
                </li>
              );
            })}
            <li>
              <button
                className="header__content-profile-button"
                onClick={() => {
                  setProfileMenuOpen(!profileMenuOpen);
                }}
              >
                <User />
                <ArrowDropDown />
              </button>
              {profileMenuOpen ? (
                <ProfileMenu setProfileMenuOpen={setProfileMenuOpen} />
              ) : (
                ""
              )}
            </li>
          </ul>
          <div className="header__content__nav-mobile-menu">
            <ProfileMenu setProfileMenuOpen={setProfileMenuOpen} />
          </div>
        </nav>
        <div className="header__content__toggle">
          <button
            className="header__content__toggle__nav-hamburger-button"
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          >
            <Hamburger />
          </button>
        </div>
      </div>
    </ContainerFluid>
  );
};
