import { proxy } from "valtio";

export const possibleServices = ["Solar", "Forest", "GeoStore"];

export const StoreScopedDomain = proxy({
  domain: {
    id: "",
    domain_id: "",
    domain: "",
    package: "",
    company_name: "",
    company_postal: "",
    company_city: "",
    company_address: "",
    company_vat: "",
    created_at: "",
    requests_length: null,
    parent_id: "",
    available_services: null,
  },
  users: [],
  requests: [],
  domain_requests: [
    {
      request_id: "",
      resolved_addr: "",
      status: "",
    },
  ],
});

// Populate scoped domain object with data.
// This data is returned for the domain modal.
export const SetScopedDomain = (scopedDomain) => {
  const {
    domain_name,
    company_name,
    company_postal,
    company_city,
    company_address,
    company_vat,
    available_services,
  } = scopedDomain;


  StoreScopedDomain.domain = {
    id: scopedDomain.domain_id,
    domain_id: scopedDomain.domain_id,
    domain: domain_name || "Nincs érték",
    company_name: company_name || "Nincs érték",
    company_postal: company_postal || "Nincs érték",
    company_city: company_city || "Nincs érték",
    company_address: company_address || "Nincs érték",
    company_vat: company_vat || "Nincs érték",
    package: scopedDomain.on_trial,
    created_at: scopedDomain.created_at,
    parent_id: scopedDomain.parent_id || 0,
    available_services: JSON.parse(available_services),
  };

  // Populate users to scoped domain
  StoreScopedDomain.users = scopedDomain.users.map((user) => {
    return {
      id: user.id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      domain: "teszt.hu",
      rule: "Adminisztrátor",
      defaultRule: "Felhasználó",
    };
  });
};

// Set domain's requests length
export const SetScopedDomainReqs = (requestsList) => {
  StoreScopedDomain.domain.requests_length = requestsList.length;
};

// Populate domain's requests
export const SetScopedDomainPrevReqs = (list) => {
  const filteredList = list.filter(
    (request) => request.domain_id === StoreScopedDomain.domain.domain_id
  );
  StoreScopedDomain.domain_requests = filteredList.map((e) => {
    return {
      request_id: e.request_id,
      resolved_addr: e.resolved_addr,
      status: e.status,
    };
  });
};
