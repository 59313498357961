// Imports
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Cookies from "universal-cookie";
import { useSnapshot } from "valtio";
import { Login } from "@apis-common";
import { Checkbox, Input, Button, useToastContext } from "@components-common";
import { Logo } from "@icons";
import {
  SetRememberMe,
  SetUserEmail,
  SetUserPassword,
  StoreLogin,
} from "@store-common";

export const LoginPage = () => {
  const {
    loginEmail: email,
    loginPassword: password,
    rememberMe,
  } = useSnapshot(StoreLogin);
  const addToast = useToastContext();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create request body
    let body = {
      email: email,
      password: password,
      rememberMe: rememberMe,
    };
    // Call Login API
    Login(body, addToast);
  };

  // Cookies load if user previous saved login data
  useEffect(() => {
    const cookies = new Cookies();
    const getUsernameCookie = cookies.get("username");
    const getPasswordCookie = cookies.get("password");
    if (getPasswordCookie && getPasswordCookie) {
      SetUserEmail(getUsernameCookie);
      SetUserPassword(getPasswordCookie);
    }
  }, []);

  return (
    <div className="login-page">
      <div className="login-page__container">
        <Logo />
        <div className="login-page__container-action">
          <form onSubmit={handleSubmit}>
            <Input
              label="E-mail cím"
              value={email}
              onChange={(e) => SetUserEmail(e.target.value)}
              type="text"
              name="email"
              required={true}
            />
            <Input
              label="Jelszó"
              value={password}
              onChange={(e) => SetUserPassword(e.target.value)}
              type="password"
              name="password"
              required={true}
            />
            <Button actionType="submit">Belépés</Button>
            <div className="login-page__container-action__bottom">
              <Checkbox
                label="Jegyezzen meg"
                name="rememberMe"
                value={rememberMe}
                onChange={() => {
                  SetRememberMe(!rememberMe);
                }}
                checked={rememberMe}
              />
              <NavLink to="/forgotten">
                <Button asLink={"true"}>Elfelejtett jelszó?</Button>
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
