import { getScopedDomain, getScopedUser } from "@apis-common";
import { getScopedRequestForest } from "@apis-forest";
import { getScopedRequest } from "@apis-teto";
import { ClearScopedRequestForest } from "@store-forest";
import { ClearScopedRequest } from "@store-teto";

const getID = (type, current, modal_type, sortedData) => {
  let requestedID, idKey;
  switch (true) {
    case modal_type === "requests" || modal_type === "requestsForest" || modal_type === "requestsForestSaved":
      idKey = "id";
      break;
    case modal_type === "users":
      idKey = "id";
      break;
    case modal_type === "domain":
      idKey = "domain_id";
      break;
    default:
      break;
  }
  if (type === "prev") requestedID = sortedData[current - 1][idKey];
  if (type === "next") requestedID = sortedData[current + 1][idKey];
  return requestedID;
};

// Let request body
const createBody = (type, currentPendingIndex, modal_type, sortedData) => {
  let body = {
    token: localStorage.getItem("userToken"),
  };
  if (type === "prev") {
    body = {
      ...body,
      id: getID("prev", currentPendingIndex, modal_type, sortedData),
    };
  }
  if (type === "next") {
    body = {
      ...body,
      id: getID("next", currentPendingIndex, modal_type, sortedData),
    };
  }
  return body;
};

// Pagination function
const prevUser = (modal_type, addToast, currentPendingIndex, sortedData) => {
  if (modal_type === "requests") {
    ClearScopedRequest();
    getScopedRequest(
      createBody("prev", currentPendingIndex, modal_type, sortedData),
      addToast
    );
  }
  if (modal_type === "requestsForest" || modal_type === "requestsForestSaved") {
    ClearScopedRequestForest();
    getScopedRequestForest(
      createBody("prev", currentPendingIndex, modal_type, sortedData),
      addToast
    );
  }
  if (modal_type === "users")
    getScopedUser(
      createBody("prev", currentPendingIndex, modal_type, sortedData),
      addToast
    );
  if (modal_type === "domain") {
    getScopedDomain(
      createBody("prev", currentPendingIndex, modal_type, sortedData),
      addToast
    );
  }
};
const nextUser = (modal_type, addToast, currentPendingIndex, sortedData) => {

  if (modal_type === "requests") {
    ClearScopedRequest();
    getScopedRequest(
      createBody("next", currentPendingIndex, modal_type, sortedData),
      addToast
    );
  }
  if (modal_type === "requestsForest" || modal_type === "requestsForestSaved") {
    ClearScopedRequestForest();
    getScopedRequestForest(
      createBody("next", currentPendingIndex, modal_type, sortedData),
      addToast
    );
  }
  if (modal_type === "users")
    getScopedUser(
      createBody("next", currentPendingIndex, modal_type, sortedData),
      addToast
    );
  if (modal_type === "domain") {
    getScopedDomain(
      createBody("next", currentPendingIndex, modal_type, sortedData),
      addToast
    );
  }
};

const filterAndSetIndex = (
  modalType,
  filteredData,
  dateField,
  currentIndexSetter,
  prevStatusSetter,
  nextStatusSetter,
  sortedDataSetter,
  scopedRequest,
  scopedRequestForest,
  scopedUser,
  scopedDomain
) => {
  // Get comparable date (create number from date)
  const convertData = (data) => {
    let comparableData = data
      .replaceAll(".", "")
      .replaceAll(" ", "")
      .replaceAll(":", "");
    comparableData = Math.abs(parseInt(comparableData));
    return comparableData;
  };

  // Sorting table
  filteredData.sort(
    (a, b) => convertData(a[dateField]) - convertData(b[dateField])
  );

  // Add sorted list to reference array
  sortedDataSetter(
    filteredData
      .slice()
      .sort((a, b) => convertData(a[dateField]) - convertData(b[dateField]))
  );

  // Get index of scoped item when user opens it
  const indexOfScopedItem = filteredData.findIndex((item) => {
    if (modalType === "requests") return item.id === scopedRequest.request_id;
    if (modalType === "requestsForest" || modalType === "requestsForestSaved")
      return item.id === scopedRequestForest.request_id;
    if (modalType === "users") return item.id === scopedUser.id;
    if (modalType === "domain") return item.domain_id === scopedDomain.id;
  });

  // Set scoped index
  currentIndexSetter(indexOfScopedItem);

  // Set paginator prev status
  if (indexOfScopedItem === 0) prevStatusSetter(false);
  else prevStatusSetter(true);

  // Set paginator next status
  if (indexOfScopedItem === filteredData.length - 1) nextStatusSetter(false);
  else nextStatusSetter(true);
};

// Get comparable date (create number from date)
export const converterData = (data) => {
  let comparableData = data
    .replaceAll(".", "")
    .replaceAll(" ", "")
    .replaceAll(":", "");
  comparableData = Math.abs(parseInt(comparableData));
  return comparableData;
};

// Sorting 
const sortAndSetData = (filteredData, sortKey, setSortedData) => {

  // Reorganize data if nested arrays are present
  const flattenedData = filteredData.flat();

  // Sorting table
  const sortedData = flattenedData.sort(
    (a, b) => converterData(a[sortKey]) - converterData(b[sortKey])
  );

  // Add sorted list to reference array
  setSortedData(sortedData);

  return sortedData;
};
  

export { getID, prevUser, nextUser, filterAndSetIndex, sortAndSetData };
