// Imports
import { urls } from "@apis-common";
import { SetScopedRequestGeostore } from "@store-geostore";
import { CheckError } from "@utils-common";

// Get scoped request
export const getScopedRequestGeostore = async (body, message) => {
  await fetch(urls.requestByIDV3 + body.id, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      // Set Store
      SetScopedRequestGeostore(actualData);
    })
    .catch((error) => {
      console.log(error);
    });
};
