export const Textarea = ({
  label,
  value,
  onChange,
  name,
}) => {
  return (
    <div className="textarea">
      <label>
        {label}
        <textarea value={value} onChange={onChange} name={name} />
      </label>
    </div>
  );
};
