// Imports
import { urls } from "@apis-common";
import { SetUsersList } from "@store-common";
import { CheckError } from "@utils-common";

// Get users list
export const getUsers = async (body, message) => {
  await fetch(urls.users, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      SetUsersList(actualData);
    })
    .catch((error) => {
      console.log(error);
    });
};
