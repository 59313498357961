const ServiceTeto = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill="#3370FF" clipPath="url(#clip0_2966_579)">
        <path d="M17.67 12.618c0-.938-.546-1.526-1.442-1.526-.896 0-1.47.602-1.47 1.526v.112h2.912v-.112z"></path>
        <path
          fillRule="evenodd"
          d="M4 0a4 4 0 00-4 4v16a4 4 0 004 4h16a4 4 0 004-4V4a4 4 0 00-4-4H4zm7.67 7.228V8.95H8.854V17h-2.1V8.95H3.941V7.228h7.728zm7.82 8.666c-.56.686-1.54 1.274-3.094 1.274-2.422 0-3.696-1.442-3.696-3.752 0-2.366 1.344-3.808 3.514-3.808 2.156 0 3.486 1.414 3.486 3.668v.616h-4.942v.126c0 .924.602 1.554 1.708 1.554.854 0 1.456-.364 1.904-.896l1.12 1.218z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2966_579">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

const ServiceForest = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_3531_822)">
        <rect width="24" height="24" fill="#54C623" rx="4"></rect>
        <path
          fill="#fff"
          d="M10.97 17v-1.722H6.825v-2.366h4.004V11.19H6.825V8.95h4.144V7.228H4.726V17h6.245zM13.036 17h6.118v-1.61h-2.38v-2.492c0-.812.63-1.218 1.554-1.218h1.54V9.776h-.686c-1.316 0-2.002.924-2.31 1.876h-.098V9.776h-3.738v1.61h1.666v4.004h-1.666V17z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3531_822">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

const ServiceGeoStore = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <g fill="#FF499E" clipPath="url(#clip0_2967_593)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM15.2753 7.22803V13.8333L13.4868 7.22803H10.5188L7.87279 17H10.0008L10.6028 14.648H13.2768L13.8928 17H15.2753H16.1328H19.0273C20.8753 17 21.9813 15.95 21.9813 14.312C21.9813 12.996 21.2813 12.268 20.0773 11.974V11.89C21.0993 11.596 21.6733 10.938 21.6733 9.83203C21.6733 8.23603 20.6093 7.22803 18.7053 7.22803H15.2753ZM18.3833 15.53H17.3193V12.772H18.3833C19.3773 12.772 19.8393 13.066 19.8393 13.948V14.354C19.8393 15.25 19.3773 15.53 18.3833 15.53ZM18.1173 11.372H17.3193V8.69803H18.1173C19.0973 8.69803 19.5313 8.96403 19.5313 9.81803V10.238C19.5313 11.092 19.0973 11.372 18.1173 11.372ZM12.9548 12.954L12.0028 9.25803H11.8908L10.9388 12.954H12.9548ZM6.65826 8.95003H9.47226V7.22803H1.74426V8.95003H4.55826V17H6.65826V8.95003Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_2967_593">
          <rect
            fill="#fff"
            width="24"
            height="24"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const ServiceNull = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_2968_602)">
        <rect width="24" height="24" fill="#0A0A29" rx="4"></rect>
        <path
          fill="#fff"
          d="M8.631 17h2.52V7.228H9.36v6.496h-.154L6.98 7.228H4.46V17h1.792v-6.496h.154L8.631 17zm8.787 0h2.072V9.776h-2.072v4.746c0 .7-.63 1.05-1.274 1.05-.812 0-1.218-.49-1.218-1.4V9.776h-2.072v4.676c0 1.75.966 2.716 2.352 2.716 1.162 0 1.806-.672 2.128-1.484h.084V17z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2968_602">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};


export const IconsService = ({ type }) => {
  switch (true) {
    case type === "Solar":
      return <ServiceTeto />;
    case type === "Forest":
      return <ServiceForest />;
    case type === "GeoStore":
      return <ServiceGeoStore />;
    case type === "service null":
      return <ServiceNull />;
    default:
      break;
  }
};
