import { download } from "@crmackey/shp-write";
import { transform } from "ol/proj";
import { register } from "ol/proj/proj4";
import proj4 from "proj4";

/* 
Source: https://socket.dev/npm/package/@crmackey/shp-write 
*/


// EPSG:23700 used by the availabilityLayer JSON data
proj4.defs(
  "EPSG:23700",
  "+proj=somerc +lat_0=47.1443937222222 +lon_0=19.0485717777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=57.01,-69.97,-9.29,0,0,0,0 +units=m +no_defs +type=crs"
);
register(proj4);


// Create new domain
export const downloadShapefile = (geoJSON, fileName) => {

  // Create transformed geoJSON
  const transformedGeoJSON = {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: {
        name: "EPSG:23700",
      },
    },
    features: geoJSON.features.map((feature) => {
      return {
        type: "Feature",
        geometry: {
          type: feature.geometry.type,
          coordinates: feature.geometry.coordinates.map((polygon) => {
            return polygon.map((point) => {
              const transformedPoint = transform(
                [point[0], point[1]],
                "EPSG:3857",
                "EPSG:23700"
              );
              return transformedPoint;
            });
          }),
        },
        properties: { ...geoJSON.properties },
      };
    }),
  };

  const options = {
    // Zip file name
    name: fileName,
    // Optional explicit names for shapefiles generated
    //for each type of geometry (applicable when mixed geometries are present)
    types: {
      point: fileName,
      polygon: fileName,
      line: fileName,
    },
  };
  // GeoJSON bridge for features
  download(transformedGeoJSON, options);
};
