// Imports
import * as THREE from "three";
import { proxy } from "valtio";
import { calculateTotalLength, calculateClosed } from "./utils";

// StoreModel
const StoreModel = proxy({
  pointer: {
    poi: new THREE.Vector3(),
    n: new THREE.Vector3(),
    la: new THREE.Vector3(),
    poiFaceIndex: null,
    poiUID: null,
    poiLayer: null,
    poiType: null,
    pointerScale: 0,
    pointerScaleFactor: 0.04,
    snapMargin: 0.4,
    snapped: false,
    visible: false,
    dragging: false,
    panelHovered: false,
  },
  tool: {
    currentTool: 0,
    tools: ["Kurzor", "Mérőszallag"],
  },
  cursor: {
    selection: {
      visible: false,
      multiselection: false,
      items: [],
    },
    shiftPressed: false,
    selectionChangeTrack: 0,
  },
  ruler: {
    points: [],
    totalLength: 0,
    closed: false,
  },
  view: {
    currentView: 0,
    views: ["Árnyékolt", "Ortofotó", "Drótváz", "LiDAR"],
    viewbarTypeOpen: false,
    lidarDetail: 1,
    lidarDetailAuto: true,
    viewbarLidarDetailOpen: false,
    features: [
      { name: "Síkok", icon: "side", active: true, available: false },
      { name: "Élek", icon: "edge", active: false, available: false },
    ],
    ortomapChangeTrack: 0,
    ortoAvailable: false,
  },
  model: {
    gps: new THREE.Vector3(),
    sides: [],
    edges: [],
    bounds: {
      max: 100,
      min: 100,
      xMid: 0,
      zMid: 0,
      zoomMin: 20,
      zoomMax: 70,
    },
    basePlaneMargin: 20,
    loaded: false,
    ready: false,
  },
  imageGenerationTrack: 0
});


export const ResetView = () => {
  StoreModel.model.loaded = false;
  StoreModel.model.ready = false;
  StoreModel.model.sides = [];
  StoreModel.model.edges = [];
  StoreModel.view.currentView = 0;
  StoreModel.view.lidarDetail = 1;
  StoreModel.view.lidarDetailAuto = true;
};

export const changeOrtomapChangeTrack = () => {
  StoreModel.view.ortomapChangeTrack += 1;
};

export const setReady = () => {
  StoreModel.model.ready = true;
};

export const setMedia = (size) => {
  if (size >= 1200) {
    StoreModel.view.media = "desktop";
  }
  else if (size >= 672) {
    StoreModel.view.media = "tablet";
  }
  else {
    StoreModel.view.media = "mobile";
  }
};

export const handleKeyDown = (event) => {
  if (event.key === "Escape") {
    resetTool(StoreModel.tool.currentTool === 0 ? 0 : 1);
  }
  if (event.key === "Tab") {
    setCurrentTool(StoreModel.tool.currentTool === 0 ? 1 : 0);
  }
  if (event.key === "Shift") {
    StoreModel.cursor.shiftPressed = true;
  }
};

export const handleKeyUp = (event) => {
  if (event.key === "Shift") {
    StoreModel.cursor.shiftPressed = false;
  }
};

export const setCurrentTool = (id) => {
  resetTool(id === 0 ? 1 : 0);
  StoreModel.tool.currentTool = id;
};

export const handlePointerClick = (geometry, shiftPosition, centerPoint) => {
  if (StoreModel.tool.currentTool === 0 && !StoreModel.pointer.dragging) {
    if (!StoreModel.cursor.shiftPressed && StoreModel.cursor.selection.items.length > 0) {
      resetTool(0);
    }

    if (StoreModel.cursor.selection.items.length === 0) {
      StoreModel.cursor.selection.visible = true;
    }

    if (
      StoreModel.cursor.selection.items.filter((e) => e.uID === StoreModel.pointer.poiUID)
        .length === 0
    ) {
      StoreModel.cursor.selection.items.push({
        uID: StoreModel.pointer.poiUID,
        type: StoreModel.pointer.poiType,
        g: geometry,
        sP: shiftPosition,
        cP: centerPoint,
      });
    } else {
      let index = StoreModel.cursor.selection.items.findIndex(
        (e) => e.uID === StoreModel.pointer.poiUID
      );
      StoreModel.cursor.selection.items.splice(index, 1);
    }

    StoreModel.cursor.selectionChangeTrack += 1;
  } else if (StoreModel.tool.currentTool === 1 && !StoreModel.pointer.dragging) {
    if (StoreModel.ruler.closed) StoreModel.ruler.points = [];

    let newPoint = new THREE.Vector3();
    Object.assign(newPoint, StoreModel.pointer.poi);
    StoreModel.ruler.points.push(newPoint);
  }
};

export const handleCursorPanelClose = () => {
  StoreModel.cursor.selectionEmptyTrack += 1;
  resetTool(0);
};


export const setMeasurements = () => {
  StoreModel.ruler.totalLength = calculateTotalLength(StoreModel.ruler.points);
  StoreModel.ruler.closed = calculateClosed(StoreModel.ruler.points);
};

export const handleMeasurementCopy = (length) => {
  navigator.clipboard.writeText(length);
};

export const resetTool = (tool) => {
  if (tool === 0) {
    StoreModel.cursor.selection.visible = false;
    StoreModel.cursor.selection.items = [];
    StoreModel.pointer.panelHovered = false;
  } else if (tool === 1) {
    StoreModel.ruler.points = [];
    StoreModel.pointer.snapped = false;
  }
};

export const handleViewPanelTypeOpen = () => {
  StoreModel.view.viewbarTypeOpen = !StoreModel.view.viewbarTypeOpen;
};

export const handleViewTypeChange = (id) => {
  StoreModel.view.currentView = id;
  StoreModel.view.viewbarTypeOpen = !StoreModel.view.viewbarTypeOpen;
};

export const handleViewDetailOpen = () => {
  StoreModel.view.viewbarLidarDetailOpen = !StoreModel.view.viewbarLidarDetailOpen;
};

export const handleViewDetailChange = (id) => {
  if (id === 0) {
    StoreModel.view.lidarDetailAuto = true;
    StoreModel.view.lidarDetail = 1;
  } else {
    StoreModel.view.lidarDetailAuto = false;
    StoreModel.view.lidarDetail = id;
  }
  StoreModel.view.viewbarLidarDetailOpen = !StoreModel.view.viewbarLidarDetailOpen;
};

export const handleFeatureChange = (id) => {
  StoreModel.view.features[id].active = !StoreModel.view.features[id].active;
};

export const handleViewHomeing = () => {
  StoreModel.view.homeingTrack += 1;
};

export const handleViewFlyover = () => {
  StoreModel.view.autoRotate = !StoreModel.view.autoRotate;
};

export const setModelViewerFullscreen = () => {
  if (StoreModel.view.fullScreen && StoreModel.view.helpOpen) StoreModel.view.helpOpen = false;

  if (StoreModel.view.fullScreen) StoreModel.view.autoRotate = true;
  else StoreModel.view.autoRotate = false;

  StoreModel.view.fullScreen = !StoreModel.view.fullScreen;
  resetTool(StoreModel.tool.currentTool);
};

export const setModelViewerHelp = () => {
  StoreModel.view.helpOpen = !StoreModel.view.helpOpen;
};

export const setImageGenerationTrack = () => {
  StoreModel.imageGenerationTrack += 1;
};

export default StoreModel;
