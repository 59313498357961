// Imports
import { urls } from "@apis-common";
import { CheckError } from "@utils-common";

// Delete scoped user
export const deleteScopedUser = async (body, message, modalOpen) => {
  await fetch(urls.users + body.id, {
    method: "DELETE",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response, message))
    .then(() => {
      modalOpen(false);
      message(["success", "Felhasználó törölve!"]);
    })
    .catch((error) => {
      console.log(error);
    });
};

