// Utility functions
export const getBuildingFeature = (num) => {
  switch (true) {
    case num === 0:
      return "Épületmodell";
    case num === 1:
      return "Épületmodell + Tetőobjektumok";
    case num === 2:
      return "Épületmodell + Tetőobjektumok + Napelem ";
    default:
      break;
  }
};

export const formatDate = (unFormatedDate) => {
  // Utility functions
  const checkLength = (unformatedDateNumber) => {
    let formatedDate = unformatedDateNumber.toString();
    if (formatedDate.length < 2) formatedDate = "0" + formatedDate;
    return formatedDate;
  };

  // Return formated date
  let oldFormatedDate = unFormatedDate.slice(0, 16);
  oldFormatedDate = oldFormatedDate.replaceAll("T", " ");
  oldFormatedDate = oldFormatedDate.replaceAll("-", ".");

  const arrayDate = oldFormatedDate.split(" ");
  const arrayYearMonthDay = arrayDate[0];
  const arrayHours = checkLength(parseInt(arrayDate[1].split(":")[0]) + 1);
  const arrayMinutes = arrayDate[1].split(":")[1];

  // Create expected date format
  const formatedDate = `${arrayYearMonthDay}. ${arrayHours}:${arrayMinutes}`;

  return formatedDate;
};

export const getTMinus = (unFormatedDate) => {
  // Start date
  const startDate = new Date();

  // End date
  const endDate = new Date(unFormatedDate);

  // Calculate deadline
  endDate.setDate(endDate.getDate() + parseInt(3));
  const deadline = endDate.toISOString();
  // Calculate the difference in milliseconds
  const totalMilliseconds = Math.abs(endDate - startDate);

  // Calculate total days, hours, and minutes
  const totalDays = Math.floor(totalMilliseconds / (1000 * 60 * 60 * 24));

  // Calculate total weekends (Saturday and Sunday)
  let totalWeekends = 0;
  for (let i = 0; i <= totalDays; i++) {
    const currentDate = new Date(startDate.getTime() + i * 1000 * 60 * 60 * 24);
    if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
      totalWeekends++;
    }
  }

  // Get how far deadline is
  const total = Date.parse(deadline) - Date.parse(new Date());
  const minutes = Math.floor((total % (1000 * 60 * 60)) / (1000 * 60));
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  const totalhours = hours + days * 24;
  const totalWeekendsHours = totalWeekends * 24;
  const additionalTime =
    Math.sign(totalhours) === -1 ? totalWeekendsHours + 2 : 2;
  // Create object
  const tminus = {
    sec: total,
    hours: totalhours + additionalTime,
    minutes: minutes,
  };

  // Return value
  return tminus;
};

export const getDomainService = (serviceName) => {
  switch (true) {
    case serviceName === "Solar":
      return "EnviMAP Tető";
    case serviceName === "Forest":
      return "EnviMAP Erdő";
    case serviceName === "GeoStore":
      return "EnviMAP Téradatbank";
    default:
      break;
  }
};

export const formattedNumber = (number) => {
  // Convert the number to a string
  const numberString = number.toString();

  // If the length of the number is less than or equal to 3, no formatting is needed
  if (numberString.length <= 3) {
    return numberString;
  }

  // Extract the initial part that won't be formatted
  const initialPart = numberString.slice(0, numberString.length % 3 || 3);

  // Format the remaining parts
  const formattedParts = [];
  for (let i = initialPart.length; i < numberString.length; i += 3) {
    formattedParts.push(numberString.slice(i, i + 3));
  }

  // Combine the initial part and formatted parts with spaces
  return initialPart + " " + formattedParts.join(" ");
};
