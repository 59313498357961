// Imports
import { useContext, useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { createDomain } from "@apis-common";
import {
  Button,
  Checkbox,
  Input,
  InputDouble,
  ModalContext,
  Select,
  useToastContext,
} from "@components-common";
import { Modal } from "@modals";
import { StoreDomainsList } from "@store-common";

export const ModalDomainNew = ({ isModalOpen }) => {
  const [formData, setFormData] = useState({});
  const { modalOpen } = useContext(ModalContext);
  const addToast = useToastContext();
  const snapDomains = useSnapshot(StoreDomainsList);
  const [superDomains, setSuperDomains] = useState([]);

  // Handle input change
  const handleInput = (e) => {
    setFormData((prevFormData) => {
      const { name, value, type, checked } = e.target;
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create request body
    let body = {
      token: localStorage.getItem("userToken"),
      domain_name: formData.domain,
      company_name: formData.company_name,
      company_postal: formData.company_postal,
      company_city: formData.company_city,
      company_address: formData.company_address,
      company_vat: formData.company_vat,
      on_trial: formData.on_trial,
      parent_id: formData.parent_id,
    };

    // Call create new domain endpoint
    createDomain(body, addToast, modalOpen, setFormData);
  };

  // Set select options
  useEffect(() => {
    if (snapDomains) {
      const superDomainsList = snapDomains.filter(
        (domain) => domain.is_super_domain
      );
      let superDomainsSelectList = [
        {
          value: 0,
          label: "Nincsen",
        },
      ];

      superDomainsList.map((item) => {
        const SelectOption = {
          value: item.domain_id,
          label: item.domain_name,
        };
        superDomainsSelectList.push(SelectOption);
      });
      setSuperDomains(superDomainsSelectList);
    }
  }, [snapDomains]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      type="domain"
      xxlModal="true"
      title="Új domain létrehozása"
      pagination={false}
    >
      <main>
        <form className="modal__form" onSubmit={(e) => handleSubmit(e)}>
          <div className="modal__form__fields">
            <div className="modal__form__fields-group">
              <Input
                label="Domain"
                type="text"
                value={formData.domain === undefined ? "" : formData.domain}
                onChange={handleInput}
                name="domain"
              />
              <Input
                label="Cég"
                type="text"
                value={
                  formData.company_name === undefined
                    ? ""
                    : formData.company_name
                }
                onChange={handleInput}
                name="company_name"
              />
              <InputDouble
                labelLeft="IRSZ"
                labelRight="Város"
                typeLeft="text"
                typeRight="text"
                valueLeft={
                  formData.company_postal === undefined
                    ? ""
                    : formData.company_postal
                }
                valueRight={
                  formData.company_city === undefined
                    ? ""
                    : formData.company_city
                }
                onChangeLeft={handleInput}
                onChangeRight={handleInput}
                nameLeft="company_postal"
                nameRight="company_city"
              />
              <Input
                label="Cím"
                type="text"
                value={
                  formData.company_address === undefined
                    ? ""
                    : formData.company_address
                }
                onChange={handleInput}
                name="company_address"
              />
              <Input
                label="Adószám"
                type="text"
                value={
                  formData.company_vat === undefined ? "" : formData.company_vat
                }
                onChange={handleInput}
                name="company_vat"
              />
              <Select
                label="Super domain"
                options={superDomains}
                onChange={handleInput}
                name="parent_id"
                selected={formData.parent_id}
                value={formData.parent_id}
              />
              <Checkbox
                label="Tesztidőszakra van állítva a domain?"
                name="on_trial"
                value={formData.on_trial === undefined ? "" : formData.on_trial}
                onChange={handleInput}
              />
            </div>
          </div>
          <div className="modal__actions">
            <Button actionType="submit">Új domain létrehozása</Button>
          </div>
        </form>
      </main>
    </Modal>
  );
};
