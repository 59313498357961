// Imports
import { transform } from "ol/proj";
import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import { useContext } from "react";
import { snapshot } from "valtio";
import { getDomains, getScopedDomain, getScopedUser } from "@apis-common";
import { ModalContext, useToastContext } from "@components-common";
import { ArrowForward, Copy } from "@icons";
import { StoreScopedRequesGeostore } from "@store-geostore";
import { formattedNumber } from "@utils-common";

// EPSG:23700 used by the availabilityLayer JSON data
proj4.defs(
  "EPSG:23700",
  "+proj=somerc +lat_0=47.1443937222222 +lon_0=19.0485717777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=57.01,-69.97,-9.29,0,0,0,0 +units=m +no_defs +type=crs"
);
register(proj4);

export const CardModalGeostore = ({ type, data, saved }) => {
  const { coordinates, domain, user, request_data } = snapshot(
    StoreScopedRequesGeostore.request
  );
  const { modalType, modalCurrentType } = useContext(ModalContext);
  const addToast = useToastContext();

  // Convert coordinates to EOV cooords
  const coordinatesEOV = transform(
    [coordinates.lng, coordinates.lat],
    "EPSG:4326",
    "EPSG:23700"
  );

  // Copy-paste coordinates
  const handleCopyLocation = () => {
    // Get coordinates and format text
    let copiedText = `${coordinatesEOV[0]}, ${coordinatesEOV[1]}`;
    // Copy the text
    navigator.clipboard.writeText(copiedText);
    // Show message after copied
    addToast(["success", "Koordináták másolva!"]);
  };

  // Open model with scoped user
  const openModalWithContent = () => {
    let body = {
      token: localStorage.getItem("userToken"),
      id: user.id,
    };
    let bodyID = {
      token: localStorage.getItem("userToken"),
      id: domain.domain_id,
    };
    getScopedUser(body, addToast);
    getDomains(body, addToast);
    getScopedDomain(bodyID, addToast);
    modalType("users");
  };

  const checkPanelDataInstantPurchase = (panelData) =>
    panelData.some((product) => !product.instantPurchase);

  return (
    <div className="card__modal">
      {type === "date" && (
        <>
          <div className="card__modal-header">
            <span className="span--bold">Dátum</span>
          </div>
          <div className="card__modal-meta">
            <span>Beküldve: {data.created_at}</span>
            {data.tminus ? (
              <span className="span--bold">
                {`${Math.abs(data.tminus.hours)}óra ${Math.abs(
                  data.tminus.minutes
                )}perc `}
                {data.tminus.minutes > 0 ? "a határidőig" : "késésben"}
              </span>
            ) : (
              <span>Szerkesztve: {data.updated_at}</span>
            )}
          </div>
        </>
      )}

      {type === "location" && (
        <>
          <div className="card__modal-header">
            <span className="span--bold">EOV koordináták</span>
            <button onClick={handleCopyLocation}>
              <Copy />
            </button>
          </div>
          <div className="card__modal-meta">
            <span>{coordinatesEOV[0]}</span>
            <span>{coordinatesEOV[1]}</span>
          </div>
        </>
      )}

      {type === "request-type" && (
        <>
          <div className="card__modal-header">
            <span className="span--bold">
              {" "}
              {modalCurrentType === "requestsGeostore" && data.selected_layers
                ? data.selected_layers.length + " termék"
                : saved
                ? "Terület"
                : "Igény csomag"}
            </span>
          </div>
          <div className="card__modal-meta">
            {modalCurrentType === "requestsGeostore" ? (
              <>
                <span className="span--bold">
                  {data.price && formattedNumber(data.price)} Ft
                  {checkPanelDataInstantPurchase(request_data.selectedLayers) &&
                    "-tól"}
                </span>
                <span>
                  {data.ha} km<sup>2</sup>{" "}
                </span>
              </>
            ) : saved ? (
              <>
                <span>{data.ha} hektár</span>
                <span className="span--bold">194 000 Ft - 263 000 Ft</span>
              </>
            ) : (
              <>
                <span>{data.building_type}</span>
                <span className="span--bold">{data.ha} hektár</span>
              </>
            )}
          </div>
        </>
      )}

      {type === "user" && (
        <>
          <div className="card__modal-header">
            <span className="span--bold">Felhasználó</span>
            <button onClick={openModalWithContent}>
              <ArrowForward />
            </button>
          </div>
          <div className="card__modal-meta">
            <span>{data[0].last_name + " " + data[0].first_name}</span>
            <span className="span--bold">
              {data[1].domain_name} {saved && "| 1.6 DLV"}{" "}
            </span>
          </div>
        </>
      )}
    </div>
  );
};
