// Imports
import { urls } from "@apis-common";
import { getRequests } from "@apis-teto";
import { setScopedRequestStatus } from "@store-teto";
import { CheckError } from "@utils-common";
import { getScopedRequestGeostore } from "./getScopedRequest";

// Edit scoped request
export const editScopedRequestGeostore = async (body, message) => {
  await fetch(urls.requestByIDV3 + body.id, {
    method: "PATCH",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      status: body.status,
      comment: body.comment,
      resolved_address: body.resolved_address,
      request_data: body.request_data,
    }),
  })
    .then((response) => CheckError(response, message))
    .then(() => {
      setScopedRequestStatus(body.status);
      // Set message is denied is successfully
      if (body.status === "Denied") message(["success", "Sikeres elutasítás!"]);
      // Refresh scoped user
      getScopedRequestGeostore(body, message);
      // Refresh table
      getRequests(body, message);
    })
    .catch((error) => {
      console.log(error);
    });
};
