export const calculateInitZoom = (polygonExtent, maxViewExtent) => {
  const polygonWidth = polygonExtent[2] - polygonExtent[0];
  const polygonHeight = polygonExtent[3] - polygonExtent[1];
  const maxViewWidth = maxViewExtent[2] - maxViewExtent[0];
  const maxViewHeight = maxViewExtent[3] - maxViewExtent[1];

  const widthRatio = maxViewWidth / polygonWidth;
  const heightRatio = maxViewHeight / polygonHeight;

  const ratio = Math.min(widthRatio, heightRatio);
  const initZoom = Math.log2(1 / ratio);

  return Math.floor(initZoom);
};
