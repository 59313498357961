import { proxy } from "valtio";
import { formatDate } from "@utils-common";

// Default states
export const StoreUsersList = proxy([
  {
    first_name: "",
    last_name: "",
    id: "",
    domain_name: "",
    domain_id: "",
    available_services: "",
    requests_number: "",
    is_active: "",
    is_admin: "",
    reg_date: "",
    login_date: "",
    total_requests: "",
  },
]);

// Set users list
export const SetUsersList = (body) => {
  // Empty array
  StoreUsersList.splice(0, StoreUsersList.length);

  // Mapping data and add to array
  body.map((user) => {
    let userObj = {
      first_name: user.first_name,
      last_name: user.last_name,
      id: user.id,
      domain_name: user.domain.domain_name,
      domain_id: user.domain_id,
      available_services: user.available_services,
      is_active: user.is_active,
      is_admin: user.is_admin,
      reg_date: formatDate(user.reg_date),
      login_date: user.login_date ? formatDate(user.login_date) : "-",
      total_requests: user.total_requests,
    };
    return StoreUsersList.push(userObj);
  });
};
