// Imports
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { getUsers } from "@apis-common";
import {
  Table,
  TableUsers,
  useMultiFilter,
  useToastContext,
} from "@components-common";
import { StoreUsersList } from "@store-common";

export const UsersPage = () => {
  const snap = useSnapshot(StoreUsersList);
  const addToast = useToastContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({});
  const filterableColums = [
    "first_name",
    "last_name",
    "domain_name",
    "domain_id",
  ];
  const filteredData = useMultiFilter(
    snap,
    searchQuery,
    filters,
    filterableColums
  );

  // Fetch data
  useEffect(() => {
    let body = {
      token: localStorage.getItem("userToken"),
    };
    getUsers(body, addToast);
  }, [addToast]);

  return (
    <Table
      title="Felhasználók"
      searchButtons={false}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      setFilters={setFilters}
      filters={filters}
    >
      <TableUsers data={filteredData} />
    </Table>
  );
};
