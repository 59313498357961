const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_ENV}/image/upload`;

// Send calculations data to Terran
export const uploadScreenshot = async (body) => {
  await fetch(url, {
    method: "POST",
    body: body,
  })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
};
