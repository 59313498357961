// Imports
import { useState } from "react";
import { Button } from "@components-common";
import { Bin, UserFilled } from "@icons";
import { Modal } from "@modals";

const Data = [
  {
    email: "peter.enyedi@envirosense.hu (Te)",
    rule: "Adminisztrátor",
    canDelet: false,
  },
  {
    email: "csaba.lenart@envirosense.hu",
    rule: "Adminisztrátor",
    canDelet: true,
  },
  {
    email: "emese.karaszi@envirosense.hu",
    rule: "Felhasználó",
    canDelet: true,
  },
  {
    email: "laszlo.takacs@envirosense.hu",
    rule: "Felhasználó",
    canDelet: true,
  },
  {
    email: "laszlo.dusek@envirosense.hu",
    rule: "Felhasználó",
    canDelet: true,
  },
  {
    email: "tamas.tomor@envirosense.hu",
    rule: "Adminisztrátor",
    canDelet: true,
  },
];

const DropDown = ({ defaultRule }) => {
  const [rule, setRule] = useState(defaultRule);
  const handleRule = (e) => {
    if (e.target.value === "admin") {
      setRule("Adminisztrátor");
    } else if (e.target.value === "user") {
      setRule("Felhasználó");
    }
  };
  return (
    <label>
      <select value={rule} onChange={handleRule}>
        <option value={rule} hidden>
          {rule}
        </option>
        <option value="admin">Adminisztrátor</option>
        <option value="user">Felhasználó</option>
      </select>
    </label>
  );
};

const UserItem = ({ myKey, email, canDelet, defaultRule }) => {
  return (
    <div className="modal__content-user-right-item" key={myKey}>
      <div className="modal__content-user-right-item__left">
        <UserFilled />
        <div>
          <h4>{email}</h4>
          <span className="action-buttons">
            <DropDown defaultRule={defaultRule} />
          </span>
        </div>
      </div>
      <div className="modal__content-user-right-item__right">
        <span className={!canDelet ? "empty-icon" : ""}>
          {canDelet ? <Bin color="red" /> : ""}
        </span>
      </div>
    </div>
  );
};

export const ModalUsersDropDown = ({ isModalOpen }) => {
  const [listShow, setListShow] = useState(true);

  const formHandler = (e) => {
    e.preventDefault();
    console.log("Form kezelve...");
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      title={"Felhasználók"}
      hasFooter={true}
      pagination={false}
    >
      <form onSubmit={formHandler} className="modal__form-fixed">
        <div className="modal__content-input">
          <div className="modal__content-input-container">
            <div className="modal__content-input-group">
              <input
                placeholder="Meghívó küldése email címre..."
                id="invitePeople"
                name="invitePeople"
                type="email"
                onFocus={() => {
                  setListShow(false);
                }}
                onBlur={() => {
                  setListShow(true);
                }}
              />

              <Button buttonType="neutral">Meghívás</Button>
            </div>
          </div>
        </div>
        <div
          style={{ display: listShow ? "block" : "none" }}
          className="modal__content-user-right"
        >
          {Data.map((element, index) => {
            return (
              <UserItem
                key={index}
                myKey={index}
                email={element.email}
                rule={element.rule}
                canDelet={element.canDelet}
                defaultRule={element.rule}
              />
            );
          })}
        </div>
        <div className="modal__form-fixed-actions">
          <Button buttonType="neutral">Mégse</Button>
          <Button actionType="submit">Mentés</Button>
        </div>
      </form>
    </Modal>
  );
};
