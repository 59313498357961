/*
API POST for
TERRÁN Roof Calculator

Staging domain
"https://terran.develop.y-collective.hu/wp-json/roof-calculator/v1"

URL query parameters:
calculationID

Body:
roof_area                 float     Tető felületek 3D kiterjedése összesen
avg_angle                 float     Minden tetőfelület terület alapján súlyozott dőlésszög átlaga
horizontal_ridge_length   float     Taréjgerinc elemeinek összesített hossza
edge_ridge_length         float     Élgerinc elemeinek összesített hossza
gable_length              float     Oromszegély elemeinek összesített hossza
drain_length              float     Ereszcsatorna elemeinek összesített hossza
vapa_length               float     Vápa elemeinek összesített hossza
images                    array     Kép URL-eket tartalmazó tömb

*/

const url = "https://terranteto.hu/wp-json/roof-calculator/v1";

// Send calculations data to Terran
export const postTerranCalculation = async (calculationID, body, message) => {
  await fetch(`${url}/calculations/${calculationID}`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8" 
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        message(["success", "Sikeres beküldés a Terrán felé!"]);
      } else if (response.status === 400) {
        message(["error", "Terrán: Hibás formátumú adat!"]);
      } else if (response.status === 404) {
        message(["error", "Terrán: Nem található calculationID!"]);
      }
    })
    .catch((error) => {
      console.log(error);
      message(["error", "Hiba történt a Terrán felé!"]);
    });
};
