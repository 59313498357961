import { useRef, useState, useEffect } from "react";
import { Bin } from "@icons";

export const InputFileUpload = ({
  label,
  name,
  placeholder,
  accept,
  multiple = false,
  handleChange,
  handleDelete,
  files,
}) => {
  const inputRef = useRef();
  const [drop, setDrop] = useState(false);
  const acceptString = "." + accept.join(", .");

  useEffect(() => {
    if (!files.filename) {
      inputRef.current.value = "";
    }
  }, [files]);

  return (
    <div className="input__upload">
      <span className="span--bold">{label}</span>
      <div
        className={`input__upload__filebox ${multiple ? "multi" : ""} ${
          files.filename ? "filled" : ""
        } ${drop ? "drop" : ""}`}
        onDragOver={() => setDrop(true)}
        onDrop={() => setDrop(false)}
        onDragLeave={() => setDrop(false)}
      >
        <input
          ref={inputRef}
          name={name}
          type="file"
          accept={acceptString}
          multiple={multiple}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        {!files.filename ? (
          <span>+ {placeholder}</span>
        ) : (
          <div className="input__upload__filebox-filled">
            <div className="input__upload__filebox-filled__browse">
              <span>{files.filename}</span>
              {multiple && (
                <ul className="filetypes">
                  {accept.map((type, i) => {
                    return (
                      <li
                        key={i}
                        className={`${files.files[type] ? "filled" : ""}`}
                      >
                        {type}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>

            <button
              className="input__upload__filebox-filled__remove"
              name={name}
              onClick={handleDelete}
            >
              <Bin />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
