// Imports
import { Header, MainRouter } from "@components-common";

const App = () => {
  return (
    <MainRouter>
      <Header />
    </MainRouter>
  );
};

export default App;
